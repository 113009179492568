import axios from 'axios';
import global from '../globalconfig.json';
export const LOADING = 'LOADING';
export const GET_LOGINLOG_SUCCESS = 'GET_LOGINLOG_SUCCESS';
export const GET_LOGINLOG_ERROR = 'GET_LOGINLOG_ERROR';
export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_ERROR = 'GET_ACTIVITY_ERROR';
export const GET_ACTIVITYLOG_SUCCESS = 'GET_ACTIVITYLOG_SUCCESS';
export const GET_ACTIVITYLOG_ERROR = 'GET_ACTIVITYLOG_ERROR';

export const GetLoginLog = () => {

    return (dispatch) => {
        dispatch({ type: LOADING })
        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/getLoginLog',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            var responsedata = response.data.data;
            if (response.data.status_code === "200" && response.data.status === "success") {
                GetLoginLogSuccess(responsedata, dispatch);
            }
            else {
                GetLoginLogError(responsedata, dispatch);
            }
        })
            .catch(function (error) {
            });
    };

}

export const GetLoginLogSuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_LOGINLOG_SUCCESS,
        payload: responsedata
    });
}

export const GetLoginLogError = (responsedata, dispatch) => {
    dispatch({
        type: GET_LOGINLOG_ERROR,
        payload: responsedata
    });
}


export const GetLoginLogByID = (ID) => {

    return (dispatch) => {
        dispatch({ type: LOADING })
        axios({
            method: 'post',
            url: global.baseUrl + 'api/v1/getLoginLog',
            headers: {
                'Authorization': global.customertoken,
                'Content-Type': 'application/json'
            },
            data: {
                "client_id": ID,
            },
        }).then(function (response) {
            var responsedata = response.data.data;
            if (response.data.status_code === "200" && response.data.status === "success") {
                GetLoginLogByIDSuccess(responsedata, dispatch);
            }
            else {
                GetLoginLogByIDError(responsedata, dispatch);
            }
        })
            .catch(function (error) {
            });
    };

}

export const GetLoginLogByIDSuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_LOGINLOG_SUCCESS,
        payload: responsedata
    });
}

export const GetLoginLogByIDError = (responsedata, dispatch) => {
    dispatch({
        type: GET_LOGINLOG_ERROR,
        payload: responsedata
    });
}


export const GetActivityLog = () => {

    return (dispatch) => {
        dispatch({ type: LOADING })
        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/getActivityLog',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            }
        }).then(function (response) {
            var responsedata = response.data.data;
            if (response.data.status_code === "200" && response.data.status === "success") {
                GetactivityLogSuccess(responsedata, dispatch);
            }
            else {
                GetactivityLogError(responsedata, dispatch);
            }
        })
            .catch(function (error) {
            });
    };

}

export const GetactivityLogSuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_ACTIVITY_SUCCESS,
        payload: responsedata
    });
}

export const GetactivityLogError = (responsedata, dispatch) => {
    dispatch({
        type: GET_ACTIVITY_ERROR,
        payload: responsedata
    });
}




export const GetActivityLogByID = (get_id) => {

    var session_client_id = localStorage.getItem('CLIID');
    var session_empid = localStorage.getItem('EMPID');
    var session_issuper = localStorage.getItem('issuper');
    var type = "";
    var client_id = "";
    if (session_issuper === "SuperAdmin") {
        type = "client_id";
        client_id = session_client_id;
    } else {
        var employeeid = String(session_empid);
        client_id = session_empid;
        var character = employeeid.substring(0, 3);
        if (character === "EMP") {
            type = "emp_id";
        } else {
            type = "cus_id";
        }
    }

    return (dispatch) => {
        dispatch({ type: LOADING })
        axios({
            method: 'post',
            url: global.baseUrl + 'api/v1/getActivityLog',
            headers: {
                'Authorization': global.customertoken,
                'Content-Type': 'application/json'
            },
            data: {
                "client_id":get_id,
                "type": type
            },
        }).then(function (response) {
            var responsedata = response.data.data;
            if (response.data.status_code === "200" && response.data.status === "success") {
                GetActivityLogSuccess(responsedata, dispatch);
            }
            else {
                GetActivityLogError(responsedata, dispatch);
            }
        })
            .catch(function (error) {
            });
    };

}

export const GetActivityLogSuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_ACTIVITYLOG_SUCCESS,
        payload: responsedata
    });
}

export const GetActivityLogError = (responsedata, dispatch) => {
    dispatch({
        type: GET_ACTIVITYLOG_ERROR,
        payload: responsedata
    });
}


