import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import masterReducer from './masterReducer';
import getemailReducer from './getemailReducer';
import getevariabledetails from './getvariableReducer';
import updateemailReducer from './updateemailReducer';
import getprofileReducer from './getprofileReducer';
import updatevariableReducer from './updatevariableReducer';
import changepasswordReducer from './changepasswordReducer';
import getpermissionReducer from './getpermissionReducer';
import resetpasswordReducer from './resetpasswordReducer';
import updateprofileReducer from './updateprofileReducer';
import getemailtemplateReducer from './getemailtemplateReducer';
import getsmstemplateReducer from './getsmstemplateReducer';
import packageReducer from './packagesReducer';
import updatemasterReducer from './updatemasterReducer';
import SetcustomerPasswordReducer from './CustomerReducer';
import SetcustomerResetPasswordReducer from './CustomerResetPasswordReducer';
import assignpackageReducer from './assignpackageReducer';
import smsapitemplateReducer from './smsapitemplateReducer';
import EmployeeReducer from './EmployeeReducer';
import getpackageReducer from './getpackageReducer';
import SocialEmialContactReducer from './SocialEmialContactReducer';
import getcompanyReducer from './getcompanyReducer';
import ModuleReducer from './ModuleReducer';
import LoginlogReducer from './LoginlogReducer';


const rootReducer = combineReducers({
  session: sessionReducer,
  master: masterReducer,
  getemail: getemailReducer,
  getvariable: getevariabledetails,
  updateemail: updateemailReducer,
  getprofile: getprofileReducer,
  updatevariable: updatevariableReducer,
  changepassword: changepasswordReducer,
  getpermission: getpermissionReducer,
  resetpassword: resetpasswordReducer,
  updateprofile: updateprofileReducer,
  getemailtemplate: getemailtemplateReducer,
  getsmstemplate: getsmstemplateReducer,
  packages: packageReducer,
  updatemaster: updatemasterReducer,
  custreducer: SetcustomerPasswordReducer,
  custresetreducer: SetcustomerResetPasswordReducer,
  assignreducer: assignpackageReducer,
  smsapitemplate: smsapitemplateReducer,
  employee: EmployeeReducer,
  getpackages: getpackageReducer,
  custmerbasicinfo: SocialEmialContactReducer,
  getcompany: getcompanyReducer,
  module:ModuleReducer,
  loginlog:LoginlogReducer
});

export default rootReducer;
