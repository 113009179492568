import axios from 'axios';
import global from '../globalconfig.json';
import firebase from 'firebase';
import { ActivityLog } from 'src/actions';

export const CUSTOMER_CREATE_PASSWORD_ERROR = 'CUSTOMER_CREATE_PASSWORD_ERROR';
export const CUSTOMER_CREATE_PASSWORD_SUCCESS = 'CUSTOMER_CREATE_PASSWORD_SUCCESS';

export const CUSTOMER_RESET_AND_SET_PASSWORD_ERROR = 'CUSTOMER_RESET_AND_SET_PASSWORD_ERROR';
export const CUSTOMER_RESET_AND_SET_PASSWORD_SUCCESS = 'CUSTOMER_RESET_AND_SET_PASSWORD_SUCCESS';

// ---------DJ------------
export const ADD_CUSTOMER_ERROR = "ADD_CUSTOMER_ERROR";
export const ADD_CUSTOMER_SUCCESS = "ADD_CUSTOMER_SUCCESS";

export const GET_ALL_CUSTOMER_SUCCESS = 'GET_ALL_CUSTOMER_SUCCESS';
export const GET_ALL_CUSTOMER_ERROR = 'GET_ALL_CUSTOMER_ERROR';

export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';

export const CUSTOMER_STATUS_SUCCESS = 'CUSTOMER_STATUS_SUCCESS';
export const CUSTOMER_STATUS_ERROR = 'CUSTOMER_STATUS_ERROR';

export const GET_CUSTOMER_ID = 'GET_CUSTOMER_ID';

export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

export const LOADING = 'LOADING';

export const setCustomerPassword = (values, ID, history) => {

  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/v1/setCustomerPassword',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
      data: {
        "email": values.email,
        "password": values.password,
        "title_id": ID,
        "emptype_id": "Admin"
      },
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          firebase.auth().createUserWithEmailAndPassword(values.email,values.password)
          .catch(function(error) { });
          customerpasswordsuccess(responsedata, dispatch);
          history.push("/management/managementcustomers")

        }
        else {
          customerpassworderror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const customerpasswordsuccess = (responsedata, dispatch) => {
  dispatch({
    type: CUSTOMER_CREATE_PASSWORD_SUCCESS,
    payload: responsedata
  });
}

export const customerpassworderror = (responsedata, dispatch) => {
  dispatch({
    type: CUSTOMER_CREATE_PASSWORD_ERROR,
    payload: responsedata
  });
}


export const setCustomerResetPassword = (values, CID) => {



  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/v1/CustomerResetPasswordBySuperAdmin',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
      data: {
        "newpassword": values.password,
        "client_id": CID,
      },
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          customerresetpasswordsuccess(responsedata, dispatch);

        }
        else {
          customerresetpassworderror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const customerresetpasswordsuccess = (responsedata, dispatch) => {
  dispatch({
    type: CUSTOMER_RESET_AND_SET_PASSWORD_SUCCESS,
    payload: responsedata
  });
}

export const customerresetpassworderror = (responsedata, dispatch) => {
  dispatch({
    type: CUSTOMER_RESET_AND_SET_PASSWORD_ERROR,
    payload: responsedata
  });
}


//  ------------ ADD CUSTOMER -----------
export const addcustomer = (values, email, phone, social, history, coordinatespick, pluscode) => {

  var finalpluscode = "";
  if (pluscode === "" || pluscode === null) {
    finalpluscode = "";
  } else {
    finalpluscode = pluscode;
  }

  var workstart, workend;
  if (values.workstart1 === "") {
    var time = new Date(values.workstart)
    workstart = time.getHours() + ":" + time.getMinutes();
  } else {
    workstart = values.workstart1;
  }

  if (values.workend1 === "") {
    var times = new Date(values.workend)
    workend = times.getHours() + ":" + times.getMinutes();
  } else {
    workend = values.workend1;
  }

  var vatnumber = "";

  if (values.businessentity === "no") {
    vatnumber = "no";

  } else {
    vatnumber = values.vatno;
  }

  return dispatch => {


    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/v1/createCustomer',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
      data: {
        "businessentity": values.businessentity,
        "vatnumber": vatnumber,
        "visibility": values.visiblity,
        "taxpayer": values.taxpayer,
        "title_id": values.title,
        "name": values.name,
        "surname": values.surname,
        "username": values.username,
        "country": values.country,
        "age": values.age,
        "comment": values.comment,
        "edudegree_id": values.education,
        "businessprmissis_id": values.business,
        "address": values.address,
        "lat": coordinatespick.lat,
        "long": coordinatespick.lng,
        "gender": values.gender,
        "workstart": workstart,
        "workend": workend,
        "industry_id": values.industry,
        "website": values.website,
        "emailnoti": String(values.emailnoti),
        "smsnoti": String(values.smsnoti),
        "pushnoti": String(values.pushnoti),
        "notification": "no",
        "email_module": email,
        "contact_module": phone,
        "social_module": social,
        "pluscode": finalpluscode
      },
    })
      .then(function (response) {


        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          addcustomersuccess(responsedata, dispatch);
          history.push('/management/managementcustomers')
        }
        else {
          addcustomererror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const addcustomersuccess = (responsedata, dispatch) => {
  dispatch({
    type: ADD_CUSTOMER_SUCCESS,
    payload: responsedata
  });
}

export const addcustomererror = (responsedata, dispatch) => {
  dispatch({
    type: ADD_CUSTOMER_ERROR,
    payload: responsedata
  });
}

// ---------------- GET CUSTOMER ------------

export const getallcustomer = () => {


  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'get',
      url: global.baseUrl + 'api/v1/getAllCustomer',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
    })
      .then(function (response) {


        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {

          getallcustomersuccess(responsedata.data, dispatch);
          // dispatch(GetEmailSettingsActions())
        }
        else {
          getallcustomererror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const getallcustomersuccess = (responsedata, dispatch) => {
  dispatch({
    type: GET_ALL_CUSTOMER_SUCCESS,
    payload: responsedata
  });
}

export const getallcustomererror = (responsedata, dispatch) => {
  dispatch({
    type: GET_ALL_CUSTOMER_ERROR,
    payload: responsedata
  });
}

// ---------- GET CUSTOMER BY ID ------------

export const getcustomer = (cusid) => {


  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/v1/getCustomerByID',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title_id": cusid.toString()
      }
    })
      .then(function (response) {
        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          // setTimeout(() => {
          // getmasterbyid('title', responsedata.data.title_id).then((result) => { responsedata.data.titlename = result });
          // getmasterbyid('industry', responsedata.data.industry_id).then((idsresult) => { responsedata.data.industryname = idsresult });
          // getmasterbyid('edudegree', responsedata.data.edudegree_id).then((eduresult) => { responsedata.data.educationname = eduresult });
          // getmasterbyid('businesspremises', responsedata.data.businessprmissis_id).then((buresult) => { responsedata.data.businessname = buresult });
          // getdatabyid('customeremail', cusid, 'emailmodule', 'emailmodule_id').then((eresult) => {
          //   responsedata.data.emaildetail = eresult
          // });
          // getdatabyid('customercontact', cusid, 'phone', 'contactmodule_id').then((cresult) => {
          //   responsedata.data.contactdetail = cresult
          // });
          // getdatabyid('customersocial', cusid, 'social', 'socialmodule_id').then((sresult) => {
          //   responsedata.data.socialdetail = sresult
          // });
          // }, 3000);
          responsedata.data.ID = cusid;
          //  setTimeout(() => {
          getcustomersuccess(responsedata.data, dispatch);
          // }, 1000);
        }
        else {
          getcustomererror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const getcustomersuccess = (responsedata, dispatch) => {
  dispatch({
    type: GET_CUSTOMER_SUCCESS,
    payload: responsedata
  });
}

export const getcustomererror = (responsedata, dispatch) => {
  dispatch({
    type: GET_CUSTOMER_ERROR,
    payload: responsedata
  });
}


function getmasterbyid(ntbl, tblid) {

  return axios({
    method: 'post',
    url: global.baseUrl + 'api/admin/getMasterByID',
    headers: {
      'Authorization': global.apitoken,
      'Content-Type': 'application/json'
    },
    data: {
      "nameti": ntbl,
      "title_id": tblid
    }
  })
    .then(function (response) {

      var responsedata = response.data;

      if (response.data.status_code === "200" && response.data.status === "success") {
        return responsedata.data.value;
      }
      else {
      }

    }.bind(this))
    .catch((error) => { });
}


// --- --- --- --- GET CUSTOMER STATUS --- --- --- --- --- 

export const customerstatus = (value) => {

  var finalstatus;
  if (value.status === true) {
    finalstatus = "false"
  }
  else {
    finalstatus = "true"
  }

  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/v1/changestatusCustomer',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title_id": value.cusid,
        "status": finalstatus
      }
    })
      .then(function (response) {


        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {

          customerstatussuccess(responsedata, dispatch)
          dispatch(getallcustomer())
        }
        else {

          customestatusrerror(responsedata, dispatch)
          dispatch(getallcustomer())

        }

      })
      .catch((error) => {

      })
  }
}
export const customerstatussuccess = (responsedata, dispatch) => {
  dispatch({
    type: CUSTOMER_STATUS_SUCCESS,
    payload: responsedata
  });
}

export const customestatusrerror = (responsedata, dispatch) => {
  dispatch({
    type: CUSTOMER_STATUS_ERROR,
    payload: responsedata
  });
}


export const getcustomerid = (id, dispatch) => {
  return dispatch => {
    dispatch({
      type: GET_CUSTOMER_ID,
      payload: id
    })
  }
}

// --------------------------------------------------------------


export const UpdateCustomer = (value, history, coordinatespick, WorkStart, WorkEnd, pluscode) => {

  console.log(value, "here data")
  var finalpluscode = "";

  if (pluscode === "" || pluscode === null) {
    finalpluscode = value.pluscode;
  } else {
    finalpluscode = pluscode;
  }

  var timenew = WorkStart.getHours() + ":" + WorkStart.getMinutes()
  var timenew1 = WorkEnd.getHours() + ":" + WorkEnd.getMinutes()
  var finallat;
  var finallong;
  if (coordinatespick.lat === null) {
    finallat = value.lat;
  } else {
    finallat = coordinatespick.lat;
  }

  if (coordinatespick.lng === null) {
    finallong = value.long;
  } else {
    finallong = coordinatespick.lng;
  }


  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/v1/updateCustomer',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
      data: {
        "cus_id": value.ID,
        "title_id": value.title_id,
        "name": value.name,
        "surname": value.surname,
        "username": value.username,
        "country": value.country,
        "age": value.age,
        "comment": value.comment,
        "edudegree_id": value.edudegree_id,
        "businessprmissis_id": value.businessprmissis_id,
        "address": value.address,
        "lat": finallat,
        "long": finallong,
        "emailnoti": String(value.emailnoti),
        "smsnoti": String(value.smsnoti),
        "pushnoti": String(value.pushnoti),
        "gender": value.gender,
        "workstart": timenew,
        "workend": timenew1,
        "industry_id": value.industry_id,
        "website": value.website,
        "notification": "no",
        "pluscode": finalpluscode
      }
    })
      .then(function (response) {
        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          updatesuccess(responsedata, dispatch);
          dispatch(getcustomer(value.ID));
        }
        else {
          updateerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const updatesuccess = (responsedata, dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: responsedata
  });
}

export const updateerror = (responsedata, dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMER_ERROR,
    payload: responsedata
  });
}
