
function AuthGuard() {
  var superadmindata = localStorage.getItem("superadmin");
  if (!superadmindata) {
    return false;
  } else {
    return true;
  }
}

export default AuthGuard;
