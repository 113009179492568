import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';



export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS';
export const GET_CUS_PERMISSION_SUCCESS = 'GET_CUS_PERMISSION_SUCCESS';
export const GET_CUS_PERMISSION_ERROR = 'GET_CUS_PERMISSION_ERROR';
export const UPDATE_PERMISSION_SUCCESS = 'UPDATE_PERMISSION_SUCCESS';
export const UPDATE_PERMISSION_ERROR = 'UPDATE_PERMISSION_ERROR';
export const LOADING = 'LOADING';


export const GetPermissionActions = () => {

  return async (dispatch) => {

    dispatch({ type: LOADING })

    await axios({
      method: 'get',
      url: global.baseUrl + 'api/admin/getAllSuperPermission',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },

    })
      .then(async function (response) {

        if (response.data.status_code === "200" && response.data.status === "success") {

          var responsedata = response.data.data;

          getpermission(responsedata, dispatch);
          ActivityLog("GET_PERMISSION")
        }
        else {

        }

      }.bind(this))
      .catch(function (error) {

      });
  };
}

export const getpermission = (responsedata, dispatch) => {
  dispatch({
    type: GET_PERMISSION_SUCCESS,
    payload: responsedata
  });
}


export const updatepermission = (value, id) => {
  return async (dispatch) => {

    dispatch({ type: LOADING })

    await axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/updateSuperPermission',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "document_id": id,
        "add": value.add.toString(),
        "edit": value.edit.toString(),
        "disable": value.disable.toString(),
        "view": value.view.toString()
      }
    })
      .then(async function (response) {

        if (response.data.status === "success" && response.data.status_code === "200") {
          var responsedata = response.data;
          permissionupdate(responsedata, dispatch);
          dispatch(GetPermissionActions());
          ActivityLog("UPDATE_PERMISSION")
        }
        else {
          updatepermissionerror(responsedata, dispatch);
        }


      })
      .catch(function (error) {

      });
  }
}

export const permissionupdate = (responsedata, dispatch) => {
  dispatch({
    type: UPDATE_PERMISSION_SUCCESS,
    payload: responsedata
  });
}

export const updatepermissionerror = (responsedata, dispatch) => {
  dispatch({
    type: UPDATE_PERMISSION_ERROR,
    payload: responsedata
  });
}



export const GetCusPermissionActions = (CLIID) => {

  return async (dispatch) => {

    dispatch({ type: LOADING })

    await axios({
      method: 'post',
      url: global.baseUrl + 'api/v1/getAllCustomerPermission',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
      data: {
        "client_id": CLIID
      }
    })
      .then(async function (response) {
        if (response.data.status_code === "200" && response.data.status === "success") {

          var responsedata = response.data.data;
          getcuspermission(responsedata, dispatch);

        }
        else {
          getcuspermissionerror(responsedata, dispatch);
        }

      }.bind(this))
      .catch(function (error) {

      });
  };
}

export const getcuspermission = (responsedata, dispatch) => {
  dispatch({
    type: GET_CUS_PERMISSION_SUCCESS,
    payload: responsedata
  });
}
export const getcuspermissionerror = (responsedata, dispatch) => {
  dispatch({
    type: GET_CUS_PERMISSION_ERROR,
    payload: responsedata
  });
}

