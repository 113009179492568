import axios from 'axios';
import global from '../globalconfig.json';
import { GetEmailSettingsActions } from 'src/actions';
import { ActivityLog } from 'src/actions';


export const EMAIL_UPDATE_SUCCESS = 'EMAIL_UPDATE_SUCCESS';
export const EMAIL_UPDATE_ERROR = 'EMAIL_UPDATE_ERROR';
export const LOADING = 'LOADING';


export const emailupdate = (values) => {


  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/updateEmailSettings',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "service": values.service,
        "port": values.port,
        "user": values.user,
        "password": values.password

      },
    })
      .then(function (response) {


        var responsedata = response.data;
        if (response.data.status_code === "200" && response.data.status === "success") {
          updatesuccess(responsedata, dispatch);
          dispatch(GetEmailSettingsActions())
          ActivityLog("UPDATE_EMAIL")
        }
        else {
          emailupdateerror(responsedata, dispatch);
        }

      }.bind(this))
      .catch((error) => {
      
      });
  };
}

export const updatesuccess = (responsedata, dispatch) => {
  dispatch({
    type: EMAIL_UPDATE_SUCCESS,
    payload: responsedata
  });
}

export const emailupdateerror = (responsedata, dispatch) => {
  dispatch({
    type: EMAIL_UPDATE_ERROR,
    payload: responsedata
  });
}
