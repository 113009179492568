/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import BarChartIcon from '@material-ui/icons/BarChart';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

export default [
  {
    items: [
      {
        title: 'Overview',
        href: '/overview',
        icon: HomeIcon
      },
      {
        title: 'Dashboards',
        href: '/dashboards',
        icon: DashboardIcon,
        items: [
          {
            title: 'Default',
            href: '/dashboards/default'
          },
          {
            title: 'Analytics',
            href: '/dashboards/analytics'
          }
        ]
      },
      {
        title: 'Settings',
        href: '/settings',
        icon: SettingsIcon,
        items: [
          {
            title: 'General',
            href: '/settings/genaral'
          },
          {
            title: 'Security',
            href: '/settings/security'
          },
          {
            title: 'Company',
            href: '/settings/company'
          },
          {
            title: 'Email',
            href: '/settings/email'
          },
          {
            title: 'Variables',
            href: '/settings/variable'
          },
          {
            title: 'Permissions',
            href: '/settings/permission'
          },
          {
            title: 'Log',
            href: '/settings/log'
          },
          {
            title: 'Activity Log',
            href: '/settings/activitylog'
          },



        ]
      },
      {
        title: 'Master',
        href: '/management',
        icon: BarChartIcon,
        items: [
          {
            title: 'Business Premises',
            href: '/management/customers/BusinessPremises'
          },
          {
            title: 'Company Type',
            href: '/management/customers/Companytype'
          },
          {
            title: 'Department',
            href: '/management/customers/Department'
          },
          {
            title: 'Employee Type',
            href: '/management/customers/EmployeeType'
          },
          {
            title: 'Education Degree',
            href: '/management/customers/Educationdegree'
          },
          {
            title: 'Email',
            href: '/management/customers/Email'
          },
          {
            title: 'Industry',
            href: '/management/customers/Industry'
          },
          {
            title: 'Module',
            href: '/management/customers/Module'
          },
          {
            title: 'Phone',
            href: '/management/customers/Phone'
          },
          {
            title: 'SocialMedia',
            href: '/management/customers/SocialMedia'
          },
          {
            title: 'Title',
            href: '/management/customers/Title'
          },

        ]
      },
      {
        title: 'Management',
        href: '/management',
        icon: BarChartIcon,
        items: [
          {
            title: 'Customers',
            href: '/management/managementcustomers'
          },
          // {
          //   title: 'Package',
          //   href: '/management/packagemanagement'
          // },
        ]
      },
      {
        title: 'Template',
        href: '/templates',
        icon: ReceiptIcon,
        items: [
          {
            title: 'Email Template',
            href: '/templates/emailtemplate'
          },
          {
            title: ' SMS Template',
            href: '/templates/smstemplate'
          },
          {
            title: 'SMS API',
            href: '/templates/smsapi'
          },

        ]

      },
     
    ]
  }
];
