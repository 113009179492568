import * as actionTypes from 'src/actions';

const initialState = {
  loggedIn: true,
  loading: false,
  getemaildetails: []
};

const getemailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMAIL_SETTINGS: {
      return {
        ...state, getemaildetails: action.payload, loading: false
      };
    }

    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        getemaildetails: {
          role: 'GUEST'
        }
      };
    }

    default: {
      return state;
    }
  }
};

export default getemailReducer;
