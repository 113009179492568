import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  error: false,
  getassigndetails: [],
  successmessage: '',
  errormessage: '', message: ''
};

const getassignpackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSIGN_PACKAGE: {
      return {
        ...state, getassigndetails: action.payload, loading: false, error: false
      };
    }

    case actionTypes.ASSIGN_PACKAGE_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, error: false, message:'Package assigned successfully!'
      };
    }

    case actionTypes.LOADING: {
      return {
        ...state, loading: true,
      };
    }
    case actionTypes.ASSIGN_PACKAGE_ERROR: {
      return {
        ...state, loading: false, error: true, errormessage: action.payload
      };
    }

    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }

    default: {
      return state;
    }
  }
};



export default getassignpackageReducer;
