import * as actionTypes from 'src/actions';

let initialState = {
    loggedIn: true,
    loading: false,
    error: false,
    log: [],
    activitylog:[],
    CusActivityLog:[]
};

const LoginlogReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LOGINLOG_ERROR: {
            return { ...state, log: action.payload, loading: false, error: true };
        }

        case actionTypes.GET_LOGINLOG_SUCCESS: {
            return { ...state, log: action.payload, loading: false, error: false };
        }
        case actionTypes.GET_ACTIVITY_SUCCESS: {
            return { ...state, activitylog: action.payload, loading: false, error: true };
        }

        case actionTypes.GET_ACTIVITY_ERROR: {
            return { ...state, activitylog: action.payload, loading: false, error: false };
        }
        case actionTypes.GET_ACTIVITYLOG_SUCCESS: {
            return { ...state, CusActivityLog: action.payload, loading: false, error: true };
        }

        case actionTypes.GET_ACTIVITYLOG_ERROR: {
            return { ...state, CusActivityLog: action.payload, loading: false, error: false };
        }
        case actionTypes.LOADING: {
            return {
                ...state, loading: true
            };
        }
        case actionTypes.NULL_DATA: {
            return { ...state, loading: false, error: false };
        }
        default: {
            return state;
        }
    }
};

export default LoginlogReducer;