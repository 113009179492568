import * as actionTypes from 'src/actions';

const initialState = {
  loggedIn: false,
  user: [],
  error: false,
  errormessage: '',
  successmessage: '',
  loading: false,
  message:''
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...state, user: action.payload, loggedIn: true, loading: false
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }
    case actionTypes.SESSION_LOGIN_ERROR: {
      return { ...state, errormessage: 'Email or Password Invalid!', error: true, loading: false };
    }

    case actionTypes.FORGOT_SUCCESS: {
      return { ...state, loading: false, error: false, successmessage: action.payload };
    }

    case actionTypes.FORGOT_ERROR: {
      return { ...state, loading: false, error: true, errormessage: 'Email Id is Incorrect!' };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }
    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
