import * as actionTypes from 'src/actions';

const initialState = {
  // masterupdated: '',
  loading: false,
  successmessage: "",
  message: "",
  errormessage: "",
  error: false,
};

const updatemasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MASTER_UPDATE_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, error: false, message: "Record Updated Successfully."
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true,
      };
    }
    case actionTypes.MASTER_UPDATE_ERROR: {
      return {
        ...state, loading: false, error: true, errormessage: action.payload, message: "Error Occured.", error: true
      };
    }

    case actionTypes.MASTER_DELETE_SUCCESS: {
      return {
        ...state, loading: false, successmessage: action.payload, message: "Record Delete Successfully.", error: false
      };
    }

    case actionTypes.MASTER_DELETE_ERROR: {
      return {
        ...state, loading: false, errormessage: action.payload, message: "Error Occured.", error: true
      };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }
    default: {
      return state;
    }
  }
};

export default updatemasterReducer;
