import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';

export const GET_ASSIGN_PACKAGE = 'GET_ASSIGN_PACKAGE';

export const ASSIGN_PACKAGE_SUCCESS = 'ASSIGN_PACKAGE_SUCCESS';
export const ASSIGN_PACKAGE_ERROR = 'ASSIGN_PACKAGE_ERROR';

export const LOADING = 'LOADING';

export const GetAllPackageList = () => {

  return async (dispatch) => {

    await axios({
      method: 'get',
      url: global.baseUrl + 'api/admin/getAllSuperPackage',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
    })
      .then(async function (response) {

        if (response.data.status_code === "200" && response.data.status === "success") {

          var responsedata = response.data.data;
          await getAssignldetail(responsedata, dispatch);
        }
        else {

        }

      }.bind(this))
      .catch(function (error) {

      });
  };
}

export const getAssignldetail = (responsedata, dispatch) => {
  dispatch({
    type: GET_ASSIGN_PACKAGE,
    payload: responsedata
  });
}


export const AssignPackage = (value, ID, history) => {


  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/v1/createCustomerpackage',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
      data: {
        "client_id": ID,
        "title_id": value,
      },
    })
      .then(function (response) {

        if (response.data.status_code === "200" && response.data.status === "success") {

          var responsedata = response.data;
          assignpackagesuccess(responsedata, dispatch);
          history.push('/management/managementcustomers')
        }
        else {
          assignpackageerror(responsedata, dispatch);
        }

      }.bind(this))
      .catch(function (error) {
        assignpackageerror(error, dispatch);

      });
  };

}


export const assignpackagesuccess = (responsedata, dispatch) => {
  dispatch({
    type: ASSIGN_PACKAGE_SUCCESS,
    payload: responsedata
  });
}

export const assignpackageerror = (responsedata, dispatch) => {
  dispatch({
    type: ASSIGN_PACKAGE_ERROR,
    payload: responsedata
  });
}
