import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  error: false,
  errormessage: "",
  successmessage: "",
  message: ""
};

const updateemailReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EMAIL_UPDATE_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, error: false, message: "Email Settings Updated Successfully."
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true,
      };
    }
    case actionTypes.EMAIL_UPDATE_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error: true, message: 'Error In Update Email Settings.'
      };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }
    default: {
      return state;
    }
  }
};

export default updateemailReducer;
