import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';

export const LOADING = 'LOADING';

export const ADD_PACKAGES_SUCCESS = 'ADD_PACKAGES_SUCCESS';
export const ADD_PACKAGES_ERROR = 'ADD_PACKAGES_ERROR';

export const GET_ALL_PACKAGES_SUCCESS = 'GET_ALL_PACKAGES_SUCCESS';
export const GET_ALL_PACKAGES_ERROR = 'GET_ALL_PACKAGES_ERROR';

export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_ERROR = 'GET_PACKAGES_ERROR';

export const PACKAGE_STATUS_SUCCESS = 'PACKAGE_STATUS_SUCCESS';
export const PACKAGE_STATUS_ERROR = 'PACKAGE_STATUS_ERROR';

//  ---------------- ADD PACKAGES -------------------------
export const addpackage = (values, modules, history) => {

    return dispatch => {

        dispatch({ type: LOADING });

        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/createSuperPackage',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "packagename": values.pakagename,
                "price": parseFloat(values.price),
                "commission": parseFloat(values.commision),
                "duration": values.duration,
                "totallicence": parseInt(values.totlalicence),
                "module": modules
            },
        })
            .then(function (response) {
                var responsedata = response.data;
                if (response.data.status_code === "200" && response.data.status === "success") {
                    addpackagesuccess(responsedata, dispatch)
                    history.push("/management/packagemanagement");
                }
                else {
                    addpackageerror(responsedata, dispatch)
                }
            }.bind(this))
            .catch((error) => {
            });
    };
}
export const addpackagesuccess = (responsedata, dispatch) => {
    dispatch({
        type: ADD_PACKAGES_SUCCESS,
        payload: responsedata
    });
}

export const addpackageerror = (responsedata, dispatch) => {
    dispatch({
        type: ADD_PACKAGES_ERROR,
        payload: responsedata
    });
}
//  --------------- END PACKAGES -----------------------


//  --- GET PACKAGES BY ID ---
export const getpackage = (pkgid) => {

    return dispatch => {

        dispatch({ type: LOADING });

        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/getSuperPackageByID',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "title_id": pkgid
            },
        })
            .then(function (response) {
                var responsedata = response.data;
                if (response.data.status_code === "200" && response.data.status === "success") {
                    // for (let i = 0; i < responsedata.data.module.length; i++) {
                    //     if (responsedata.data.module[i].moduleid !== "") {
                    //         getmodulename(responsedata.data.module[i].moduleid).then((result) => responsedata.data.module[i].modulename = result)
                    //     }
                    // }
                    // setTimeout(() => {
                    getpackagesuccess(responsedata.data, dispatch)
                    // }, 3000);
                }
                else {
                    getpackageerror(responsedata.data, dispatch)
                }
            }.bind(this))
            .catch((error) => {
            });
    };
}

export const getpackagesuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_PACKAGES_SUCCESS,
        payload: responsedata
    });
}

export const getpackageerror = (responsedata, dispatch) => {
    dispatch({
        type: GET_PACKAGES_ERROR,
        payload: responsedata
    });
}
//  --- END PACKAGES BY ID ---


//  --- DJ GET ALL PACKAGES ---
export const getallpackage = () => {

    return dispatch => {

        dispatch({ type: LOADING });

        axios({
            method: 'get',
            url: global.baseUrl + 'api/admin/getAllSuperPackage',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
        })
            .then(function (response) {

                var responsedata = response.data;

                if (response.data.status_code === "200" && response.data.status === "success") {

                    if (responsedata.data) {
                        // getdata(responsedata.data)
                        // .then((res) => setTimeout(() => {
                        getallpackagesuccess(responsedata.data, dispatch)
                        // }, 3000))
                    }
                    else {
                        getallpackagesuccess("", dispatch)
                    }

                }
                else {
                    getallpackageerror(response.data, dispatch);
                }

            }.bind(this))
            .catch((error) => {
            });
    };
}

export const getallpackagesuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_ALL_PACKAGES_SUCCESS,
        payload: responsedata
    });
}

export const getallpackageerror = (responsedata, dispatch) => {
    dispatch({
        type: GET_ALL_PACKAGES_ERROR,
        payload: responsedata
    });
}


const getdata = async (responsedata) => {
    for (let d = 0; d < responsedata.length; d++) {
        let data = [];
        for (let j = 0; j < responsedata[d].data.module.length; j++) {
            if (responsedata[d].data.module[j].moduleid !== "") {
                getmodulename(responsedata[d].data.module[j].moduleid).then((mdresult) => { data.push(mdresult) });
            }
        }
        responsedata[d].data.modulesname = await data;
    }
    return responsedata;
}

function getmodulename(tblid) {

    return axios({
        method: 'post',
        url: global.baseUrl + 'api/admin/getMasterByID',
        headers: {
            'Authorization': global.apitoken,
            'Content-Type': 'application/json'
        },
        data: {
            "nameti": "module",
            "title_id": tblid
        }
    })
        .then(function (response) {

            var responsedata = response.data;

            if (response.data.status_code === "200" && response.data.status === "success") {
                return responsedata.data.value;
            }
            else {
            }

        }.bind(this))
        .catch((error) => { });
}

// --- END GET ALL PACKAGES ---

export const packagestatus = (value) => {

    return dispatch => {

        dispatch({ type: LOADING })

        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/changestatusSuperPackage',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "title_id": value.cusid,
                "status": JSON.stringify(!value.status)
            }
        })
            .then(function (response) {


                var responsedata = response.data;

                if (response.data.status_code === "200" && response.data.status === "success") {

                    packagestatussuccess(responsedata, dispatch)
                    dispatch(getallpackage())
                }
                else {

                    packagestatusrerror(responsedata, dispatch)
                    dispatch(getallpackage())

                }

            })
            .catch((error) => {

            })
    }
}
export const packagestatussuccess = (responsedata, dispatch) => {
    dispatch({
        type: PACKAGE_STATUS_SUCCESS,
        payload: responsedata
    });
}

export const packagestatusrerror = (responsedata, dispatch) => {
    dispatch({
        type: PACKAGE_STATUS_ERROR,
        payload: responsedata
    });
}