import uuid from 'uuid/v1';
import moment from 'moment';
import mock from 'src/utils/mock';

mock.onGet('/api/management/customers').reply(200, {
  customers: [
    {
      id: uuid(),
      name: 'Gregor Goricki',
      email: 'CLI001',
      avatar: '/images/avatars/avatar_3.png',
      spent: 'slovenia',
      type: 'Freelancer',
      currency: '$',
      projects: '3',
      rating: 4.3,
      location: 'fhfguyfrg/45645fgbghb001',
      post: 'CEO',
      status: 'Enable',
      price:'€500',
      commission:"2%",
      duration:"2 week",
      totallicense:"5",
      Module:"Tracking, module1, module2",
      packagename:"platinum"
    },
    {
      id: uuid(),
      name: 'Alexa Richardson',
      email: 'CLI002',
      avatar: '/images/avatars/avatar_4.png',
      spent: 'slovenia',
      type: 'Enterprise',
      currency: '$',
      projects: '0',
      rating: 4.5,
      location: 'fhfguyfrg/45645fgbghb001',
      post: 'CTO',  
      status: 'Disable',
      price:'€100',
      commission:"5%",
      duration:"3 week",
      totallicense:"2",
      Module:"Tracking, module1, module2",
      packagename:"Gold"
    },
    {
      id: uuid(),
      name: 'Anje Keizer',
      email: 'CLI003',
      avatar: '/images/avatars/avatar_5.png',
      spent: 'slovenia',
      type: 'Enterprise',
      currency: '$',
      projects: '6',
      rating: 4,
      location: 'fhfguyfrg/45645fgbghb001',
      post: 'CS',
      status: 'Enable',
      price:'€50',
      commission:"5%",
      duration:"1 week",
      totallicense:"4",
      Module:"Tracking, module1, module2",
      packagename:"premium"
    },
    {
      id: uuid(),
      name: 'Clarke Gillebert',
      email: 'CLI004',
      avatar: '/images/avatars/avatar_6.png',
      spent: 'slovenia',
      type: 'Agency',
      currency: '$',
      projects: '1',
      rating: 3.5,
      location: 'fhfguyfrg/45645fgbghb001',
      post: 'DIRECTOR',
      status: 'Enable',
      price:'€500',
      commission:"2%",
      duration:"2 week",
      totallicense:"5",
      Module:"Tracking, module1, module2",
      packagename:"silver"
    },
  ]
});

mock.onGet('/api/management/customers/1/invoices').reply(200, {
  invoices: [
    {
      id: uuid(),
      date: moment(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      paymentMethod: 'Credit Card',
      value: '5.25',
      currency: '$',
      status: 'paid'
    },
    {
      id: uuid(),
      date: moment(),
      description: 'Freelancer Subscription (12/05/2019 - 11/06/2019)',
      paymentMethod: 'Credit Card',
      value: '5.25',
      currency: '$',
      status: 'paid'
    }
  ]
});

mock.onGet('/api/management/customers/1/logs').reply(200, {
  logs: [
    {
      id: uuid(),
      status: 200,
      method: 'POST',
      route: '/api/purchase',
      desc: 'Purchase',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
    },
    {
      id: uuid(),
      status: 522,
      error: 'Invalid credit card',
      method: 'POST',
      route: '/api/purchase',
      desc: 'Purchase',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(2, 'minutes')
        .subtract(56, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'DELETE',
      route: '/api/products/d65654e/remove',
      desc: 'Cart remove',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(8, 'minutes')
        .subtract(23, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products/d65654e/add',
      desc: 'Cart add',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(20, 'minutes')
        .subtract(54, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products/c85727f/add',
      desc: 'Cart add',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(34, 'minutes')
        .subtract(16, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products/c85727f',
      desc: 'View product',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(54, 'minutes')
        .subtract(30, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'GET',
      route: '/api/products',
      desc: 'Get products',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(56, 'minutes')
        .subtract(40, 'seconds')
    },
    {
      id: uuid(),
      status: 200,
      method: 'POST',
      route: '/api/login',
      desc: 'Login',
      IP: '84.234.243.42',
      created_at: moment()
        .subtract(2, 'days')
        .subtract(57, 'minutes')
        .subtract(5, 'seconds')
    }
  ]
});
