import * as actionTypes from 'src/actions';

let initialState = {
    loggedIn: true,
    demodata:"",
    businesspremises: [],
    companytype: [],
    department: [],
    employeetype: [],
    edudegree: [],
    emailmodule: [],
    industry: [],
    module: [],
    phone: [],
    social: [],
    title: [],
    loading: false,
    message:"",
    successmessage:"",
    errormessage:"",
    error:false,
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_BUSINESS_PREMISES: {
            return { ...state, businesspremises: action.payload, loading: false,error:false };
        }
        case actionTypes.GET_TITLE_DEMO: {
            return { ...state, demodata: action.payload, loading: false,error:false };
        }
        case actionTypes.GET_COMPANY_TYPE: {
            return { ...state, companytype: action.payload, loading: false ,error:false};
        }
        case actionTypes.GET_DEPARTMENT: {
            return { ...state, department: action.payload, loading: false ,error:false};
        }
        case actionTypes.GET_EMPLOYEE_TYPE: {
            return { ...state, employeetype: action.payload, loading: false,error:false };
        }
        case actionTypes.GET_EDUCATION_DEGREE: {
            return { ...state, edudegree: action.payload, loading: false,error:false };
        }
        case actionTypes.GET_EMAIL_TYPE: {
            return { ...state, emailmodule: action.payload, loading: false ,error:false };
        }
        case actionTypes.GET_INDUSTRY: {
            return { ...state, industry: action.payload, loading: false ,error:false };
        }
        case actionTypes.GET_MODULE: {
            return { ...state, module: action.payload, loading: false ,error:false };
        }
        case actionTypes.GET_PHONE: {
            return { ...state, phone: action.payload, loading: false,error:false  };
        }
        case actionTypes.GET_SOCIAL: {
            return { ...state, social: action.payload, loading: false,error:false  };
        }
        case actionTypes.GET_TITLE: {
            return { ...state, title: action.payload, loading: false,error:false  };
        }
        case actionTypes.ADD_MASTER_SUCCESS: {
            return { ...state, successmessage:action.payload, message:"New Record Add Sucessfully.", loading:false, error:false };
        }
        case actionTypes.ADD_MASTER_ERROR: {
            return { ...state, errormessage:action.payload, message:"Error Occured.", loading:false, error:false };
        }
        case actionTypes.MASTER_STATUS_SUCCESS: {
            return { ...state, successmessage:action.payload, message:"Status Changed Sucessfully.", loading:false, error:false };
        }
        case actionTypes.MASTER_STATUS_ERROR: {
            return { ...state, errormessage:action.payload, message:"Error Occured.", loading:false, error:false };
        }
        case actionTypes.NULL_DATA: {
            return { ...state, errormessage:"", message:"",successmessage:"", loading:false, error:false };
        }
        case actionTypes.LOADING: {
            return {
                ...state, loading: true
            };
        }
        default: {
            return state;
        }
    }
};

export default masterReducer;