import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';

export const LOADING = 'LOADING';

export const ADD_MODULE_SUCCESS = 'ADD_MODULE_SUCCESS';
export const ADD_MODULE_ERROR = 'ADD_MODULE_ERROR';
export const GET_MODULE_SUCCESS = 'GET_MODULE_SUCCESS';
export const GET_MODULE_ERROR = 'GET_MODULE_ERROR';
export const GET_LICENSE_SUCCESS = 'GET_LICENSE_SUCCESS';
export const GET_LICENSE_ERROR = 'GET_LICENSE_ERROR';
export const GET_MODULE_BY_ID_SUCCESS = 'GET_MODULE_BY_ID_SUCCESS';
export const GET_MODULE_BY_ID_ERROR = 'GET_MODULE_BY_ID_ERROR';
export const UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS';
export const UPDATE_MODULE_ERROR = 'UPDATE_MODULE_ERROR'


export const GetModule = (get_id) => {
    return (dispatch) => {
        dispatch({ type: LOADING })
        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/getallmodulebyclientid',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "title_id": get_id
            },
        })
            .then(function (response) {
                var responsedata = response.data.data;
                if (response.data.status_code === "200" && response.data.status === "success") {
                    GetModuleSuccess(responsedata, dispatch);
                    ActivityLog("GET_MODULE")
                }
                else {
                    GetModuleError(responsedata, dispatch);
                }
            })
            .catch(function (error) {

            });
    };

}

export const GetModuleSuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_MODULE_SUCCESS,
        payload: responsedata
    });
}

export const GetModuleError = (responsedata, dispatch) => {
    dispatch({
        type: GET_MODULE_ERROR,
        payload: responsedata
    });
}

export const GetModuleByID = (get_id) => {
    return (dispatch) => {

        dispatch({ type: LOADING })
        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/getSuperPackageByID',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "title_id": get_id
            },
        })
            .then(function (response) {

                var responsedata = response.data.data;
                if (response.data.status_code === "200" && response.data.status === "success") {
                    GetModuleByIDSuccess(responsedata, dispatch);
                    ActivityLog("GET_MODULE_BY_ID")
                }
                else {
                    GetModuleByIDError(responsedata, dispatch);
                }
            })
            .catch(function (error) {

            });
    };

}

export const GetModuleByIDSuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_MODULE_BY_ID_SUCCESS,
        payload: responsedata
    });
}

export const GetModuleByIDError = (responsedata, dispatch) => {
    dispatch({
        type: GET_MODULE_BY_ID_ERROR,
        payload: responsedata
    });
}



export const AddModule = (value, finalstartdate, finalenddate, get_id) => {

    return (dispatch) => {
        dispatch({ type: LOADING })

        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/createSuperPackage',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "modulename": value.module,
                "price": value.price,
                "discount": value.discount,
                "duration": value.duration,
                "totallicence": value.data.length,
                "key": value.data.toString(),
                "keyarray": value.data,
                "startdate": finalstartdate,
                "enddate": finalenddate,
                "client_id": get_id
            },
        })
            .then(function (response) {
                var responsedata = response.data;
                if (response.data.status_code === "200" && response.data.status === "success") {

                    AddModuleSuccess(responsedata, dispatch);
                    dispatch(GetModule(get_id));
                    ActivityLog("ADD_MODULE")
                }
                else {
                    AddModuleError(responsedata, dispatch);
                }

            }.bind(this))
            .catch(function (error) {

            });
    };

}


export const AddModuleSuccess = (responsedata, dispatch) => {
    dispatch({
        type: ADD_MODULE_SUCCESS,
        payload: responsedata
    });
}

export const AddModuleError = (responsedata, dispatch) => {
    dispatch({
        type: ADD_MODULE_ERROR,
        payload: responsedata
    });
}



export const GetModuleLicence = (get_id) => {
    return (dispatch) => {

        dispatch({ type: LOADING })
        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/getLicenceByPackageID',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "title_id": get_id
            },
        })
            .then(function (response) {
                var responsedata = response.data.data;
                if (response.data.status_code === "200" && response.data.status === "success") {
                    GetLicenseSuccess(responsedata, dispatch);
                    ActivityLog("GET_MODULE_LICENSE")
                }
                else {
                    GetLiceseError(responsedata, dispatch);
                }
            })
            .catch(function (error) {

            });
    };

}

export const GetLicenseSuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_LICENSE_SUCCESS,
        payload: responsedata
    });
}

export const GetLiceseError = (responsedata, dispatch) => {
    dispatch({
        type: GET_LICENSE_ERROR,
        payload: responsedata
    });
}




export const UpdateModule = (value, id, cli_id) => {

    return (dispatch) => {


        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/updateSuperPackage',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "title_id": id,
                "price": value.price,
                "commission": value.discount,
            },
        })
            .then(function (response) {
                var responsedata = response.data;

                if (response.data.status_code === "200" && response.data.status === "success") {

                    UpdateModuleSuccess(responsedata, dispatch);
                    dispatch(GetModule(cli_id));
                    ActivityLog("UPDATE_MODULE")
                }
                else {
                    UpdateModuleError(responsedata, dispatch);
                }

            }.bind(this))
            .catch(function (error) {

            });
    };

}


export const UpdateModuleSuccess = (responsedata, dispatch) => {
    dispatch({
        type: UPDATE_MODULE_SUCCESS,
        payload: responsedata
    });
}

export const UpdateModuleError = (responsedata, dispatch) => {
    dispatch({
        type: UPDATE_MODULE_ERROR,
        payload: responsedata
    });
}

