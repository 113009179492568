import uuid from 'uuid/v1';
import moment from 'moment';
import mock from 'src/utils/mock';

mock.onGet('/api/settings/emailsetting').reply(200, {

  
  emailsetting: [
    {
      service: 'smtp.gmail.com',
      port: '587',
      user: 'test@g.com',
      password: 'bhgbhg'
    },

  ]
});
