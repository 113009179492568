import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  error: false,
  errormessage: "",
  successmessage: "",
  message: '',
};

const updateprofileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROFILE_UPDATE_SUCCESS: {
      return {
        ...state, loading: false, error: false, successmessage: action.payload, message: "Profile Update Successfully."
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true,
      };
    }
    case actionTypes.PROFILE_UPDATE_ERROR: {
      return {
        ...state, loading: false, errormessage: action.payload, error: true, message: "Error In Profile Update."
      };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }
    default: {
      return state;
    }
  }
};

export default updateprofileReducer;
