import uuid from 'uuid/v1';
import moment from 'moment';
import mock from 'src/utils/mock';

mock.onGet('/api/management/Businesses').reply(200, {
  Businesses: [

    {
      post: 'Factory',
      nofm:1,
      id:0
    },

    {
      post: 'Headquarters',
      nofm:0,
      id:1
    },
    {
      post: 'Stores',
      nofm:2,
      id:2
    },
    {
      post: 'Warehouse',
      nofm:1,
      id:3
    },
  ],



  Companytype: [

    {
      post: 'B2B',
      nofm:2,
    },

    {
      post: 'B2C',
      nofm:0,
    },
    {
      post: 'Client',
      nofm:1,
    },
    {
      post: 'Partner',
      nofm:1,
    },
    {
      post: 'Supplier',
      nofm:0,
    },
    {
      post: 'Others',
      nofm:1,
    },
  ],


  Department: [

    {
      post: 'Accounting',
      nofm:0,
    },

    {
      post: 'Healthcare',
      nofm:3,
    },
    {
      post: 'Manufacturing',
      nofm:1,
    },
  ],

  Educationdegree: [

    {
      post: 'PHD',
      nofm:1,
    },
  ],


  Email: [

    {
      post: 'Home',
      nofm:1,
    },

    {
      post: 'Newsletter',
      nofm:1,
    },
    {
      post: 'Work',
      nofm:2,
    },
    {
      post: 'Other',
      nofm:0,
    },
  ],




  Industry: [

    {
      post: 'Banking Services',
      nofm:1,
    },

    {
      post: 'Consulting',
      nofm:0,
    },
    {
      post: 'Delivery',
      nofm:3,
    },
    {
      post: 'Entertainment',
      nofm:1,
    },
    {
      post: 'Finance',
      nofm:4,
    },
    {
      post: 'Government',
      nofm:0,
    },
    {
      post: 'Information Technology',
      nofm:5,
    },
    {
      post: 'Telecommunication',
      nofm:0,
    },
    {
      post: 'Manufacturing',
      nofm:1,
    },
    {
      post: 'Non-Profit',
      nofm:0,
    },
    {
      post: 'Others',
      nofm:1,
    },
  ],


  Phone: [

    {
      post: 'Fax',
      nofm:1,
    },

    {
      post: 'Home',
      nofm:1,
    },
    {
      post: 'Mobile',
      nofm:2,
    },
    {
      post: 'Pager',
      nofm:1,
    },
    {
      post: 'SMS Marketing',
      nofm:1,
    },
    {
      post: 'Work Phone',
      nofm:1,
    },
    {
      post: 'Others',
      nofm:0,
    },
  ],



  SocialMedia: [

    {
      post: 'Facebook',
      nofm:1,
    },

    {
      post: 'Instagram',
      nofm:1,
    },
    {
      post: 'Skype',
      nofm:2,
    },
    {
      post: 'Twitter',
      nofm:1,
    },
    {
      post: 'Others',
      nofm:0,
    },
  ],

  Title: [

    {
      post: 'CEO',
      nofm:1,
    },

    {
      post: 'CFO',
      nofm:1,
    },
    {
      post: 'Technical department',
      nofm:1,
    }

  ],

  EmployeeType: [
    {
      post: 'Admin',
      nofm:1,
    },

    {
      post: 'Supervisor',
      nofm:1,
    },
    {
      post: 'User',
      nofm:1,
    }
  ],

  Modules: [
    {
      post: 'Tracking',
      nofm:1,
    },

    {
      post: 'Module 1',
      nofm:0,
    },
    {
      post: 'Module 2',
      nofm:1,
    }
  ]

});
