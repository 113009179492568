import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  errormassage: '',
  successmessage: '',
  error: false,
  message: "",
};

const changepasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state, loading: false, successmessage: action.payload, error: false, message: "Password Change Successfully."
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: false, successmessage: "", errormassage: "", error: false, message: ""
      };
    }
    case actionTypes.LOADINGFIRE: {
      return {
        ...state, loading: true,
      };
    }
    case actionTypes.CHANGE_PASSWORD_ERROR: {

      return { ...state, errormassage: action.payload, error: true, loading: false, message: "Do Not Match Password!" };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }

    default: {
      return state;
    }
  }
};

export default changepasswordReducer;
