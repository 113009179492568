import axios from 'axios';
import global from '../globalconfig.json';
import { osName, browserName } from 'react-device-detect';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const SESSION_LOGIN_ERROR = 'SESSION_LOGIN_ERROR';
export const LOADING = 'LOADING';
export const FORGOT_ERROR = 'FORGOT_ERROR';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';




export const login = (values, history) => {
  return dispatch => {
    dispatch({ type: LOADING })
    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/authenticate',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "email": values.email,
        "password": values.password
      },
    })
      .then(function (response) {
        var responsedata = response.data;
        if (response.data.status_code === "200" && response.data.status === "success") {
          loginsuccess(responsedata, dispatch);
          loginlog("LOGIN");
          localStorage.setItem("superadmin", JSON.stringify(responsedata.data));
          history.push('/overview');
        }
        else {
          loginerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const loginsuccess = (responsedata, dispatch) => {
  dispatch({
    type: SESSION_LOGIN,
    payload: responsedata
  });
}

export const loginerror = (responsedata, dispatch) => {
  dispatch({
    type: SESSION_LOGIN_ERROR,
    payload: responsedata
  });
}

export const logout = (history, dispatch) => {
  return dispatch => {
    localStorage.removeItem("superadmin");
    localStorage.clear();
    loginlog("LOGOUT");
    dispatch({ type: SESSION_LOGOUT })
    history.push('/auth/login')
  }
};

export const forgotpassword = (value, history) => {
  return dispatch => {
    dispatch({ type: LOADING })
    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/forgotpassword',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "email": value.email
      },
      timeout: 5000
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          forgotsuccess(responsedata, dispatch);
          history.push('/');
        }
        else {
          forgoterror(responsedata, dispatch);
          history.push('/');
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const forgotsuccess = (responsedata, dispatch) => {
  dispatch({
    type: FORGOT_SUCCESS,
    payload: responsedata
  });
}

export const forgoterror = (responsedata, dispatch) => {
  dispatch({
    type: FORGOT_ERROR,
    payload: responsedata
  });
}


function loginlog(activity) {
  axios({
    method: 'get',
    url: 'https://api.ipify.org?format=jsonp&callback=?',

  })
    .then(function (data) {
      var string = String(data.data);
      var startreplace = string.replace('?({"ip":"', '');
      var endreplace = startreplace.replace('"});', '');

      axios({
        method: 'get',
        url: 'https://json.geoiplookup.io/' + endreplace,

      })
        .then(function (data) {
          var Location = data.data.region + "," + data.data.country_name + "( " + data.data.country_code + " )";
          axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/insertLoginLog',
            headers: {
              'Authorization': global.apitoken,
              'Content-Type': 'application/json'
            },
            data: {
              "browser": browserName,
              "login_ip": endreplace,
              "os": osName,
              "location": Location,
              "activity": activity
            },
          })
            .then(function (response) {
            }.bind(this))
            .catch(function (error) {
            });

        }.bind(this))
        .catch(function (error) {

        });


    }.bind(this))
    .catch(function (error) {

    });

}


export const ActivityLog = (activity) => {
  axios({
    method: 'get',
    url: 'https://api.ipify.org?format=jsonp&callback=?',

  })
    .then(function (data) {
      var string = String(data.data);
      var startreplace = string.replace('?({"ip":"', '');
      var endreplace = startreplace.replace('"});', '');

      axios({
        method: 'get',
        url: 'https://json.geoiplookup.io/' + endreplace,

      })
        .then(function (data) {
          var Location = data.data.region + "," + data.data.country_name + "( " + data.data.country_code + " )";
          axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/insertActivityLog',
            headers: {
              'Authorization': global.apitoken,
              'Content-Type': 'application/json'
            },
            data: {
              "browser": browserName,
              "ip": endreplace,
              "os": osName,
              "location": Location,
              "activity": activity
            },
          })
            .then(function (response) {
            }.bind(this))
            .catch(function (error) {
            });

        }.bind(this))
        .catch(function (error) {

        });


    }.bind(this))
    .catch(function (error) {

    });

}