import * as actionTypes from 'src/actions';

const initialState = {
  getpermission: [],
  getcuspermission:[],
  successmessage: '',
  errormessage: '',
  error: false,
  message: "",
  loading: false
};

const getpermissionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERMISSION_SUCCESS: {
      return {
        ...state, getpermission: action.payload, loading: false, error: false
      };
    }
    case actionTypes.GET_CUS_PERMISSION_SUCCESS: {
      return {
        ...state, getcuspermission: action.payload, loading: false, error: false
      };
    }
    case actionTypes.GET_CUS_PERMISSION_ERROR: {
      return {
        ...state, getcuspermission: action.payload, loading: false, error: false
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }

    case actionTypes.UPDATE_PERMISSION_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, error: false, message: "Permissions has been Updated Successfully."
      };
    }

    case actionTypes.UPDATE_PERMISSION_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error: true, message: "Error In Update Permission."
      };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }
    default: {
      return state;
    }
  }
};

export default getpermissionReducer;
