import * as actionTypes from 'src/actions';

const initialState = {
  getevariabledetails: [],
  errormessage: '',
  successmessage:'',
  message:'',
  error: false
};

const getvariableReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_VARIABLE_SUCCESS: {
      return {
        ...state, getevariabledetails: action.payload, loading: false, error: false
      };
    }

    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }

    case actionTypes.GET_VARIABLE_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: true, error: true
      };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }

    default: {
      return state;
    }
  }
};

export default getvariableReducer;
