import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';


export const GET_COMPANY_SUCCESS = 'GET_COMPANY_SUCCESS';
export const GET_COMPANY_ERROR = 'GET_COMPANY_ERROR';
export const COMPANY_UPDATE_SUCCESS = 'COMPANY_UPDATE_SUCCESS';
export const COMPANY_UPDATE_ERROR = 'COMPANY_UPDATE_ERROR';
export const LOADING = 'LOADING';

export const GetCompanySettingsActions = () => {

  return async (dispatch) => {

    dispatch({ type: LOADING })

    await axios({
      method: 'get',
      url: global.baseUrl + 'api/admin/getSuperAdminCompany',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },

    })
      .then(async function (response) {

        if (response.data.status_code === "200" && response.data.status === "success") {
          var responsedata = response.data.data[0].data;
          responsedata.ID = response.data.data[0].ID;
          await getcompanydetail(responsedata, dispatch);
          ActivityLog("GET_COMPANY")
        }
        else {
          getcompanyerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const getcompanydetail = (responsedata, dispatch) => {
  dispatch({
    type: GET_COMPANY_SUCCESS,
    payload: responsedata
  });
}

export const getcompanyerror = (responsedata, dispatch) => {
  dispatch({
    type: GET_COMPANY_ERROR,
    payload: responsedata
  });
}






export const companyupdate = (values, coordinatespick, pluscode, finalimage, photo, oldpluscode, oldlat, oldlong) => {
  console.log(values, "daadaxf");
  // return;

  var finallat;
  var finallong;
  var finalpluscode = "";

  if (pluscode === "" || pluscode === null) {
    finalpluscode = oldpluscode;

  } else {
    finalpluscode = pluscode;
  }
  if (coordinatespick.lat === null) {
    finallat = oldlat;
  } else {
    finallat = coordinatespick.lat;
  }

  if (coordinatespick.lng === null) {
    finallong = oldlong;
  } else {
    finallong = coordinatespick.lng;
  }
  var photoname;
  if (finalimage === undefined) {
    photoname = photo
  } else if (finalimage === "undefined") {
    photoname = "123";
  } else {
    photoname = finalimage
  }
  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/updateSuperAdminCompany',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title_id": values.ID,
        "name": values.name,
        "address": values.address,
        "email": values.email,
        "phone": values.phone,
        "vat_number": values.vatnumber,
        "lat": finallat,
        "long": finallong,
        "pluscode": finalpluscode,
        "photo": photoname
      },
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          companyupdatesuccess(responsedata, dispatch);
          dispatch(GetCompanySettingsActions())
          ActivityLog("UPDATE_COMPANY")
        }
        else {
          companyerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const companyupdatesuccess = (responsedata, dispatch) => {
  dispatch({
    type: COMPANY_UPDATE_SUCCESS,
    payload: responsedata
  });
}

export const companyerror = (responsedata, dispatch) => {
  dispatch({
    type: COMPANY_UPDATE_ERROR,
    payload: responsedata
  });
}
