import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  errormassage: '',
  successmassage: '',
  message:''
};

const SetcustomerResetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CUSTOMER_RESET_AND_SET_PASSWORD_ERROR: {
      return {
        ...state, loading: false, errormassage: '', successmassage: action.payload
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }
    case actionTypes.CUSTOMER_RESET_AND_SET_PASSWORD_SUCCESS: {

      return { ...state, errormassage: action.payload, error: true, successmassage: '', loading: false };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormassage: "", message: "", successmassage: "", loading: false, error: false };
    }

    default: {
      return state;
    }
  }
};

export default SetcustomerResetPasswordReducer;
