import * as actionTypes from 'src/actions';

const initialState = {
 loading: false,
 customerbasicdetails: [],
 successmessage:'',
 errormessage:'',
 error:false,
 message:''
};

const SocialEmialContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CUSTOMER_DATA_SUCCESS: {
      return {
        ...state, customerbasicdetails: action.payload, loading: false,error:false, message:'',
      };
    }

    case actionTypes.GET_CUSTOMER_DATA_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false,error:true, message:'',
      };
    }

    case actionTypes.UPDATE_CUSTOMER_DATA_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, error:false, message:'Record Updated Successfully.',
      };
    }

    case actionTypes.UPDATE_CUSTOMER_DATA_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error:true, message:'Error Occured.',
      };
    }

    case actionTypes.REMOVE_CUSTOMER_DATA_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, error:false,message:'Record Remove Successfully.',
      };
    }

    case actionTypes.REMOVE_CUSTOMER_DATA_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error:true, message:'Error Occured.'
      };
    }

    case actionTypes.ADD_CUSTOMER_DATA_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, error:false, message:'Record Inserted Successfully.'
      };
    }

    case actionTypes.ADD_CUSTOMER_DATA_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error:true,message:"Error Occured."
      };
    }

    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }

    default: {
      return state;
    }
  }
};

export default SocialEmialContactReducer;
