import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';

export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_ERROR = 'GET_PROFILE_ERROR';;
export const LOADING = 'LOADING';

export const GetProfileSettingsActions = () => {

  return async (dispatch) => {

    dispatch({ type: LOADING })

    await axios({
      method: 'get',
      url: global.baseUrl + 'api/admin/getAdminProfile',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },

    })
      .then(async function (response) {
        if (response.data.status_code === "200" && response.data.status === "success") {
          var responsedata = response.data.data[0].data;
          responsedata.ID = response.data.data[0].ID;
          await getprofiledetail(responsedata, dispatch);
          ActivityLog("GET_PROFILE")
        }
        else {
          getprofileerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const getprofiledetail = (responsedata, dispatch) => {
  dispatch({
    type: GET_PROFILE_SUCCESS,
    payload: responsedata
  });
}

export const getprofileerror = (responsedata, dispatch) => {
  dispatch({
    type: GET_PROFILE_ERROR,
    payload: responsedata
  });
}
