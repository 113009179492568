import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import Overview from './views/Overview';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

function requireAuth() {
  var id = localStorage.getItem("superadmin");
  if (id === null) {
    localStorage.clear();
    history.replace("/");
  }
}

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/auth/login" />

  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        path: '/auth/resetpassword/:resetid',
        exact: true,
        component: lazy(() => import('src/views/Resetpassword'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    reuiredAuth: requireAuth(),
    component: DashboardLayout,
    routes: [
      {
        path: '/dashboards/analytics',
        exact: true,
        // component: () => AuthGaurd(DashboardDefaultView,"/dashboards/analytics"),
        component: DashboardAnalyticsView

      },

      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      // OverViews --
      {
        path: '/overview',
        exact: true,
        component: Overview
        // component: Overview
      },
      // Management --
      {
        path: '/management/customers/BusinessPremises',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/BusinessPremises'))
      },
      {
        path: '/management/customers/AddCustomer',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/ProjectCreate'))
      },
      {
        path: '/management/customers/AddEmployees',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails/ProjectCreate'))
      },
      {
        path: '/management/customers/AddPackage',
        exact: true,
        component: lazy(() => import('src/views/PackageManagementList/PackageCreate'))
      },
      {
        path: '/management/customers/Companytype',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/Companytype'))
      },
      {
        path: '/management/customers/Department',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/Department'))
      },
      {
        path: '/management/customers/Educationdegree',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/Educationdegree'))
      },
      {
        path: '/management/customers/Email',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/Email'))
      },
      {
        path: '/management/customers/Industry',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/Industry'))
      },
      {
        path: '/management/customers/EmployeeType',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/EmployeeType'))
      },
      {
        path: '/management/customers/Module',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/Module'))
      },
      {
        path: '/management/customers/Phone',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/Phone'))
      },
      {
        path: '/management/customers/SocialMedia',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/SocialMedia'))
      },
      {
        path: '/management/customers/Title',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList/Title'))
      },
      {
        path: '/management/managementcustomers',
        exact: true,
        component: lazy(() => import('src/views/ManagementCustomerList'))
      },
      {
        path: '/management/packagemanagement',
        exact: true,
        component: lazy(() => import('src/views/PackageManagementList'))
      },
      {
        path: '/management/updatepackage',
        exact: true,
        component: lazy(() => import('src/views/PackageManagementList/UpdatePackage'))
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails'))
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails'))
      },
      {
        path: '/management/managementemployeeinfo',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails/ManagementEmployeeInfo'))
      },
      // Template --
      {
        path: '/templates/emailtemplate',
        exact: true,
        component: lazy(() => import('src/views/Template/EmailTemplate'))
      },
      {
        path: '/templates/smstemplate',
        exact: true,
        component: lazy(() => import('src/views/Template/SMSTemplate'))
      },
      {
        path: '/templates/emailtemplate/addsmsapitemplateform',
        exact: true,
        component: lazy(() => import('src/views/Template/AddSMSAPITemplate'))
      },
      {
        path: '/templates/smsapi',
        exact: true,
        component: lazy(() => import('src/views/Template/SMSApi'))
      },
      {
        path: '/templates/emailtemplate/emaileditform',
        exact: true,
        component: lazy(() => import('src/views/Template/EmailEdit'))
      },
      {
        path: '/templates/emailtemplate/Smsapieditform',
        exact: true,
        component: lazy(() => import('src/views/Template/SMSApiEdit'))
      },
      {
        path: '/templates/emailtemplate/Smstemplateditform',
        exact: true,
        component: lazy(() => import('src/views/Template/SMSTemplateEdit'))
      },
      // Setting --
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('src/views/Settings'))
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('src/views/Settings'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

