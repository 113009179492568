import * as actionTypes from 'src/actions';

const initialState = {
  successmessage: '',
  loading: false,
  error: false,
  errormessage: '',
  message: ""
};

const updatevariableReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VARIABLE_UPDATE_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, error: false, message: "Variable Settings Updated Successfully."
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }
    case actionTypes.VARIABLE_UPDATE_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error: true, message: "Error In Update Variable Setting."
      };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }
    default: {
      return state;
    }
  }
};

export default updatevariableReducer;
