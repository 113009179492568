import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const LOADING = 'LOADING';
export const LOADINGFIRE = 'LOADINGFIRE';



export const changepassword = (values,get_emp_id) => {


  return dispatch => {


    dispatch({ type: LOADINGFIRE })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/changepassword',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title_id":get_emp_id,
        "newpassword": values.password,
        "cpassword": values.confirm,
        "oldpassword": values.oldpassword,

      },
    })
      .then(function (response) {


        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {

          changesuccess(responsedata, dispatch);
          ActivityLog("CHANGE_PASSWORD")
        }
        else {
          passworderror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const changesuccess = (responsedata, dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: responsedata
  });
}

export const passworderror = (responsedata, dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_ERROR,
    payload: responsedata
  });
}
