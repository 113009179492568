import axios from 'axios';
import global from '../globalconfig.json';
import { GetProfileSettingsActions } from 'src/actions';
import { ActivityLog } from 'src/actions';


export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR';
export const LOADING = 'LOADING';


export const profileupdate = (values, finalimage, photo) => {

  return dispatch => {
    var photoname;
    if (finalimage === undefined) {
      photoname = photo
    }else if(finalimage === "undefined"){
      photoname="123";
    } else {
      photoname = finalimage
    }
    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/updateAdminProfile',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "admin_id": values.ID,
        "name": values.name,
        "surname": values.surname,
        "email": values.email,
        "phone": values.phone,
        "state": values.state,
        "photo": photoname

      },
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          profileupdatesuccess(responsedata, dispatch);
          dispatch(GetProfileSettingsActions())
          ActivityLog("UPDATE_PROFILE")
        }
        else {
          profileerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const profileupdatesuccess = (responsedata, dispatch) => {
  dispatch({
    type: PROFILE_UPDATE_SUCCESS,
    payload: responsedata
  });
}

export const profileerror = (responsedata, dispatch) => {
  dispatch({
    type: PROFILE_UPDATE_ERROR,
    payload: responsedata
  });
}
