import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';

export const GET_BUSINESS_PREMISES = 'GET_BUSINESS_PREMISES';
export const GET_COMPANY_TYPE = 'GET_COMPANY_TYPE';
export const GET_DEPARTMENT = 'GET_DEPARTMENT';
export const GET_EMPLOYEE_TYPE = 'GET_EMPLOYEE_TYPE';
export const GET_EDUCATION_DEGREE = 'GET_EDUCATION_DEGREE';
export const GET_EMAIL_TYPE = 'GET_EMAIL_TYPE';
export const GET_INDUSTRY = 'GET_INDUSTRY';
export const GET_MODULE = 'GET_MODULE';
export const GET_PHONE = 'GET_PHONE';
export const GET_SOCIAL = 'GET_SOCIAL';
export const GET_TITLE = 'GET_TITLE';
export const GET_TITLE_DEMO = 'GET_TITLE_DEMO';
export const LOADING = 'LOADING';
export const GET_EMPLOYEE_BYID = 'GET_EMPLOYEE_BYID';

export const ADD_MASTER_SUCCESS = 'ADD_MASTER_SUCCESS';
export const ADD_MASTER_ERROR = 'ADD_MASTER_ERROR';

export const MASTER_STATUS_SUCCESS = 'MASTER_STATUS_SUCCESS';
export const MASTER_STATUS_ERROR = 'MASTER_STATUS_ERROR'

// ***************************** DJ DEMO **********************//

export const getmaster = (value, type) => {
    return dispatch => {
        dispatch({ type: LOADING })
        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/getMasterAll',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "nameti": value
            },
        })
            .then(
                response => {
                    var responsedata = response.data.data;
                    if (response.data.status_code === "200" && response.data.status === "success") {
                        responsedata.tablename = value;
                        responsedata.actiontype = type;
                        dispatch({
                            type: type,
                            payload: responsedata
                        })
                        ActivityLog("GET_MASTER")
                    }
                    else {

                    }
                })
            .catch(function (error) {

            });
    };

}

export const getmastertitlevalue = (value) => {
    return dispatch => {
        dispatch({
            type: GET_TITLE_DEMO,
            payload: value
        });
    };
}


export const addmaster = (value, history) => {

    return dispatch => {

        dispatch({ type: LOADING })

        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/createMaster',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "nameti": value.title,
                "title": value.description
            },
        })
            .then(function (response) {

                var responsedata = response.data;
                if (response.data.status_code === "200" && response.data.status === "success") {

                    addmastersuccess(responsedata, dispatch);
                    dispatch(getmaster(value.title, value.type));
                    ActivityLog("ADD_MASTER")
                }
                else {
                    addmastererror(responsedata, dispatch);
                }

            }.bind(this))
            .catch(function (error) {

            });
    };

}


export const addmastersuccess = (responsedata, dispatch) => {
    dispatch({
        type: ADD_MASTER_SUCCESS,
        payload: responsedata
    });
}

export const addmastererror = (responsedata, dispatch) => {
    dispatch({
        type: ADD_MASTER_ERROR,
        payload: responsedata
    });
}



// Customer Status --

export const MasterChangestatus = (value) => {
    var status = "";

    if (value.status === true) {
        status = "false";
    } else {
        status = "true";
    }
    return dispatch => {
        axios({
            method: 'post',
            url: global.baseUrl + 'api/admin/changestatusMaster',
            headers: {
                'Authorization': global.apitoken,
                'Content-Type': 'application/json'
            },
            data: {
                "nameti": value.title,
                "title_id": value.cusid,
                "status": status
            }
        })
            .then(function (response) {
                var responsedata = response.data;
                if (response.data.status_code === "200" && response.data.status === "success") {
                    masterstatuschangesuccess(responsedata,dispatch);
                    dispatch(getmaster(value.title, value.type));
                    ActivityLog("CHANGE_STATUS_MASTER")
                }
                else {
                    masterstatuschangeerror(responsedata, dispatch);
                }

            })
            .catch((error) => {

            })
    }
}
export const masterstatuschangesuccess = (responsedata, dispatch) => {
    dispatch({
        type: MASTER_STATUS_SUCCESS,
        payload: responsedata
    });
}

export const masterstatuschangeerror = (responsedata, dispatch) => {
    dispatch({
        type: MASTER_STATUS_ERROR,
        payload: responsedata
    });
}
