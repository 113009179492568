import * as actionTypes from 'src/actions';

const initialState = {
    loading: false,
    errormassage: '',
    successmessage: '',
    message: '',
    error: false,
    allemployees: [],
    employee: [],
};

const EmployeeReducer = (state = initialState, action) => {
    switch (action.type) {
        // EMPLOYEE GET ALL 
        case actionTypes.GET_ALL_EMPLOYEE_SUCCESS: {
            return {
                ...state, loading: false, errormassage: '', allemployees: action.payload, error: false, message: 'Employee GET Successfully.'
            };
        }
        case actionTypes.GET_ALL_EMPLOYEE_ERROR: {

            return { ...state, errormassage: action.payload, error: true, successmessage: '', loading: false, message: 'Error Occured!!' };
        }
        case actionTypes.GET_EMPLOYEE_BY_ID_SUCCESS: {
            return {
                ...state, loading: false, errormassage: '', employee: action.payload, error: false, message: 'Employee GET Successfully.'
            };
        }
        case actionTypes.GET_EMPLOYEE_BY_ID_ERROR: {

            return { ...state, errormassage: action.payload, error: true, successmessage: '', loading: false, message: 'Error Occured!!' };
        }
        case actionTypes.LOADING: {
            return {
                ...state, loading: true
            };
        }

        // Default state return
        default: {
            return state;
        }
    }
}

export default EmployeeReducer;