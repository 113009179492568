import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';

export const GET_CUSTOMER_DATA_SUCCESS = 'GET_CUSTOMER_DATA_SUCCESS';
export const GET_CUSTOMER_DATA_ERROR = 'GET_CUSTOMER_DATA_ERROR';
export const UPDATE_CUSTOMER_DATA_SUCCESS = 'UPDATE_CUSTOMER_DATA_SUCCESS';
export const UPDATE_CUSTOMER_DATA_ERROR = 'UPDATE_CUSTOMER_DATA_ERROR';
export const REMOVE_CUSTOMER_DATA_SUCCESS = 'REMOVE_CUSTOMER_DATA_SUCCESS';
export const REMOVE_CUSTOMER_DATA_ERROR = 'REMOVE_CUSTOMER_DATA_ERROR';
export const ADD_CUSTOMER_DATA_SUCCESS = 'ADD_CUSTOMER_DATA_SUCCESS';
export const ADD_CUSTOMER_DATA_ERROR = 'ADD_CUSTOMER_DATA_ERROR';

export const LOADING = 'LOADING';


export const GetCustomerBasicInfo = (ID, value) => {

    return dispatch => {
        dispatch({ type: LOADING })

        axios({
            method: 'post',
            url: global.baseUrl + 'api/v1/getCustomerEmailByCUId',
            headers: {
                'Authorization': global.customertoken,
                'Content-Type': 'application/json'
            },
            data: {
                "nameti": value,
                "title_id": ID
            },
        })
            .then(function (response) {
                if (response.data.status_code === "200" && response.data.status === "success") {

                    var responsedata = response.data.data;
                    getdatasuccess(responsedata, dispatch);
                    ActivityLog("GET_CUSTOMER_BASIC_INFO")
                }
                else {
                    getdataerror(responsedata, dispatch);
                }

            }.bind(this))
            .catch(function (error) {
                getdataerror(error, dispatch);

            });
    };

}

export const getdatasuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_CUSTOMER_DATA_SUCCESS,
        payload: responsedata
    });
}

export const getdataerror = (responsedata, dispatch) => {
    dispatch({
        type: GET_CUSTOMER_DATA_ERROR,
        payload: responsedata
    });
}

export const RemoveBasicInfo = (ID, value, cli_id) => {

    return dispatch => {
        dispatch({ type: LOADING })
        axios({
            method: 'delete',
            url: global.baseUrl + 'api/v1/removeCustomerEmail',
            headers: {
                'Authorization': global.customertoken,
                'Content-Type': 'application/json'
            },
            data: {
                "nameti": value,
                "title_id": ID.ID
            },
        })
            .then(function (response) {
                var responsedata = response.data;
                if (response.data.status_code === "200" && response.data.status === "success") {
                    removesuccess(responsedata, dispatch);
                    dispatch(GetCustomerBasicInfo(cli_id, value));
                    ActivityLog("REMOVE_CUSTOMER_BASIC_INFO")
                }
                else {
                    removeerror(responsedata, dispatch);
                }

            }.bind(this))
            .catch(function (error) {
                removeerror(error, dispatch);

            });
    };

}

export const removesuccess = (responsedata, dispatch) => {
    dispatch({
        type: REMOVE_CUSTOMER_DATA_SUCCESS,
        payload: responsedata
    });
}

export const removeerror = (responsedata, dispatch) => {
    dispatch({
        type: REMOVE_CUSTOMER_DATA_ERROR,
        payload: responsedata
    });
}

export const UpdateContact = (value, cli_id) => {

    return dispatch => {

        dispatch({ type: LOADING })

        axios({
            method: 'post',
            url: global.baseUrl + 'api/v1/updateCustomerEmail',
            headers: {
                'Authorization': global.customertoken,
                'Content-Type': 'application/json'
            },
            data: {

                "nameti": value.nameti,
                "emailaddress": value.Contact,
                "emailmodule_id": value.Title,
                "title_id": value.TitltID
            },
        })
            .then(function (response) {
                var responsedata = response.data;
                if (response.data.status_code === "200" && response.data.status === "success") {
                    updatesuccess(responsedata, dispatch);
                    dispatch(GetCustomerBasicInfo(cli_id, value.nameti));
                    ActivityLog("UPDATE_CUSTOMER_BASIC_INFO")
                }
                else {
                    updateerror(responsedata, dispatch);
                }

            }.bind(this))
            .catch(function (error) {
                updateerror(error, dispatch);

            });
    };

}


export const updatesuccess = (responsedata, dispatch) => {
    dispatch({
        type: UPDATE_CUSTOMER_DATA_SUCCESS,
        payload: responsedata
    });
}

export const updateerror = (responsedata, dispatch) => {
    dispatch({
        type: UPDATE_CUSTOMER_DATA_ERROR,
        payload: responsedata
    });
}



export const AddBasicInfo = (data, tbl, cli_id) => {


    return dispatch => {
        dispatch({ type: LOADING })

        axios({
            method: 'post',
            url: global.baseUrl + 'api/v1/addCustomerEmail',
            headers: {
                'Authorization': global.customertoken,
                'Content-Type': 'application/json'
            },
            data: {
                "nameti": tbl,
                "emailaddress": data.contact,
                "emailmodule_id": data.contactmodule_id,
                "customer_id": cli_id
            },
        })
            .then(function (response) {
                var responsedata = response.data;
                if (response.data.status_code === "200" && response.data.status === "success") {
                    addsuccess(responsedata, dispatch);
                    dispatch(GetCustomerBasicInfo(cli_id, tbl));
                    ActivityLog("ADD_CUSTOMER_BASIC_INFO")
                }
                else {
                    adderror(responsedata, dispatch);
                }

            }.bind(this))
            .catch(function (error) {
                adderror(error, dispatch);

            });
    };

}


export const addsuccess = (responsedata, dispatch) => {
    dispatch({
        type: ADD_CUSTOMER_DATA_SUCCESS,
        payload: responsedata
    });
}

export const adderror = (responsedata, dispatch) => {
    dispatch({
        type: ADD_CUSTOMER_DATA_ERROR,
        payload: responsedata
    });
}