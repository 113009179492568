import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  getemailtemplatedetails: [],
  getemailtemplatedetailsbyid: [],
  error: false,
  sucessmessage: '',
  errormessage: '',
  message: ''
};

const getemailtemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state, getemailtemplatedetails: action.payload, loading: false, error: false
      };
    }

    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }

    case actionTypes.GET_EMAIL_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state, getemailtemplatedetailsbyid: action.payload, loading: false, error: false
      };
    }
    case actionTypes.GET_EMAIL_TEMPLATE_BY_ID_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error: true
      };
    }

    case actionTypes.UPDATE_EMAIL_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state, sucessmessage: action.payload, loading: false, error: false, message: "Email Template Updated Succcessfully."
      };
    }

    case actionTypes.UPDATE_EMAIL_TEMPLATE_BY_ID_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error: true, message: "Error Occured."
      };
    }

    case actionTypes.ADD_EMAIL_TEMPLATE_SUCCESS: {
      return {
        ...state, sucessmessage: action.payload, loading: false, error: false, message: "Email Template Added Succcessfully."
      };
    }

    case actionTypes.ADD_EMIAL_TEMPLATE_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error: true, message: "Error Occured."
      };
    }

    case actionTypes.DELETE_EMAIL_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state, sucessmessage: action.payload, loading: false, error: false, message: "Email Template Delete Succcessfully."
      };
    }

    case actionTypes.DELETE_EMAIL_TEMPLATE_BY_ID_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error: true, message: "Error Occured."
      };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", sucessmessage: "", loading: false, error: false };
    }
    default: {
      return state;
    }
  }
};

export default getemailtemplateReducer;
