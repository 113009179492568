import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  errormassage: '',
  resetlink: "",
  successmessage: "",
  message:''
};

const resetpasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state, loading: false, successmessage: action.payload, error: false
      };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }
    case actionTypes.RESET_PASSWORD_ERROR: {

      return { ...state, errormassage: action.payload, error: true, loading: false };
    }

    case actionTypes.RESET_LINK: {
      return { ...state, resetlink: action.payload };
    }

    case actionTypes.NULL_DATA: {
      return { ...state, errormassage: "", message: "", successmessage: "", loading: false, error: false };
    }
    default: {
      return state;
    }
  }
};

export default resetpasswordReducer;
