import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';

export const GET_ALL_EMPLOYEE_SUCCESS = 'GET_ALL_EMPLOYEE_SUCCESS';
export const GET_ALL_EMPLOYEE_ERROR = 'GET_ALL_EMPLOYEE_ERROR';
export const GET_EMPLOYEE_BY_ID_SUCCESS = 'GET_EMPLOYEE_BY_ID_SUCCESS';
export const GET_EMPLOYEE_BY_ID_ERROR = 'GET_EMPLOYEE_BY_ID_ERROR';
export const LOADING = 'LOADING';


// GET EMPLOYEE BY CLIENT
export const getallemployee = (value) => {


    return dispatch => {

        dispatch({ type: LOADING })

        axios({
            method: 'post',
            url: global.baseUrl + 'api/v1/getAllEmployee',
            headers: {
                'Authorization': global.customertoken,
                'Content-Type': 'application/json'
            },
            data: {
                "client_id": value,
            },
        })
            .then(function (response) {

                var responsedata = response.data.data;

                if (response.data.status_code === "200" && response.data.status === "success") {
                    getallemployeesuccess(responsedata, dispatch);
                    ActivityLog("GET_ALL_EMPLOYEE")
                }
                else {
                    getallemployeeerror(responsedata, dispatch)
                }

            }.bind(this))
            .catch((error) => {
            });
    };
}

export const getallemployeesuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_ALL_EMPLOYEE_SUCCESS,
        payload: responsedata
    });
}

export const getallemployeeerror = (responsedata, dispatch) => {
    dispatch({
        type: GET_ALL_EMPLOYEE_ERROR,
        payload: responsedata
    });
}


// GET EMPLOYEE BY ID
export const getemployeebyid = (value) => {


    return dispatch => {
        //dispatch({ type: LOADING })

        axios({
            method: 'post',
            url: global.baseUrl + 'api/v1/getEmployeeByID',
            headers: {
                'Authorization': global.customertoken,
                'Content-Type': 'application/json'
            },
            data: {
                "title_id": value,
            },
        })
            .then(function (response) {
                var responsedata = response.data.data;

                if (response.data.status_code === "200" && response.data.status === "success") {
                    getempbyidsuccess(responsedata, dispatch);
                    ActivityLog("GET_EMPLOYEE_BY_ID")
                }
                else {
                    getempbyiderror(responsedata, dispatch)
                }

            }.bind(this))
            .catch((error) => {
            });
    };
}

export const getempbyidsuccess = (responsedata, dispatch) => {
    dispatch({
        type: GET_EMPLOYEE_BY_ID_SUCCESS,
        payload: responsedata
    });
}

export const getempbyiderror = (responsedata, dispatch) => {
    dispatch({
        type: GET_EMPLOYEE_BY_ID_ERROR,
        payload: responsedata
    });
}