import * as actionTypes from 'src/actions';

let initialState = {
    loading: false,
    message:"",
    successmessage:"",
    errormessage:"",
    error:false,
    moduledata: [],
    licensedata:[],
    moduledatabyid:[]
};

const ModuleReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_MODULE_BY_ID_SUCCESS: {
            return { ...state, moduledatabyid: action.payload, loading: false,error:false };
        }
        case actionTypes.GET_MODULE_BY_ID_ERROR: {
            return { ...state, errormessage:action.payload, message:"Error Occured.", loading:false, error:false };
        }
        case actionTypes.GET_MODULE_SUCCESS: {
            return { ...state, moduledata: action.payload, loading: false,error:false };
        }
        case actionTypes.GET_MODULE_ERROR: {
            return { ...state, errormessage:action.payload, message:"Error Occured.", loading:false, error:false };
        }
        case actionTypes.ADD_MODULE_SUCCESS: {
            return { ...state, successmessage:action.payload, message:"New Module Add Sucessfully.", loading:false, error:false };
        }
        case actionTypes.ADD_MODULE_ERROR: {
            return { ...state, errormessage:action.payload, message:"Error Occured.", loading:false, error:false };
        }
        case actionTypes.UPDATE_MODULE_SUCCESS: {
            return { ...state, successmessage:action.payload, message:"Module Updated Sucessfully.", loading:false, error:false };
        }
        case actionTypes.UPDATE_MODULE_ERROR: {
            return { ...state, errormessage:action.payload, message:"Error Occured.", loading:false, error:false };
        }

        case actionTypes.GET_LICENSE_SUCCESS: {
            return { ...state, licensedata: action.payload, loading: false,error:false };
        }
        case actionTypes.GET_LICENSE_ERROR: {
            return { ...state, errormessage:action.payload, message:"Error Occured.", loading:false, error:false };
        }
        case actionTypes.NULL_DATA: {
            return { ...state, errormessage:"", message:"",successmessage:"", loading:false, error:false };
        }
        case actionTypes.LOADING: {
            return {
                ...state, loading: true
            };
        }
        default: {
            return state;
        }
    }
};

export default ModuleReducer;