import * as actionTypes from 'src/actions';

const initialState = {
    loading: false,
    error: false,
    allpackages: [],
    packages: [],
    pageloading: true,
    successmessage: '',
    errormessage: '',
    message: ''
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        //--------------- LOADING ----------------
        case actionTypes.LOADING: {
            return {
                ...state, loading: true
            };
        }
        // ---------------------------------------

        // ------- ADD SUCCESS ---------
        case actionTypes.ADD_PACKAGES_SUCCESS: {
            return { ...state, loading: false, error: false, successmessage: action.payload, message: "Package added successfully!" };
        }
        case actionTypes.ADD_PACKAGES_ERROR: {
            return { ...state, error: true, errormessage: action.payload, loading: false, message: 'Something went wrong!' };
        }
        // ------------------------------

        // ------------ GET ALL PACKAGES ----------------
        case actionTypes.GET_ALL_PACKAGES_SUCCESS: {
            return { ...state, allpackages: action.payload, loading: false };
        }
        case actionTypes.GET_ALL_PACKAGES_ERROR: {
            return { ...state, error: true, errormessage: 'get packages error', loading: false };
        }
        // ----------------------------------------------

        //  --- --- --- GET PACKAGES BY ID --- --- --- --- 
        case actionTypes.GET_PACKAGES_SUCCESS: {
            return { ...state, packages: action.payload, loading: false };
        }

        case actionTypes.GET_PACKAGES_ERROR: {
            return { ...state, error: true, errormessage: 'get packages error', loading: false };
        }
        // --- --- --- --- --- --- --- --- --- ------------

        case actionTypes.PACKAGE_STATUS_SUCCESS: {
            return {
              ...state, errormassage: '', successmessage: action.payload, loading: false, error: false, message: 'Change Status successfully!'
            };
          }
      
          case actionTypes.PACKAGE_STATUS_ERROR: {
            return {
              ...state, errormassage: action.payload, error: true, loading: false, message: 'Error Occured!!', successmessage: ''
            };
          }

        case actionTypes.NULL_DATA: {
            return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
        }
        default: {
            return state;
        }
    }
};

export default masterReducer;
