import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';


export const GET_SMS_TEMPLATE_SUCCESS = 'GET_SMS_TEMPLATE_SUCCESS';
export const GET_SMS_TEMPLATE_BY_ID_SUCCESS = 'GET_SMS_TEMPLATE_BY_ID_SUCCESS';
export const UPDATE_SMS_TEMPLATE_SUCCESS = 'UPDATE_SMS_TEMPLATE_SUCCESS';
export const UPDATE_SMS_TEMPLATE_ERROR = 'UPDATE_SMS_TEMPLATE_ERROR';
export const ADD_SMS_TEMPLATE_SUCCESS = 'ADD_SMS_TEMPLATE_SUCCESS';
export const ADD_SMS_TEMPLATE_ERROR = 'ADD_SMS_TEMPLATE_ERROR';
export const DELETE_SMS_TEMPLATE_BY_ID_SUCCESS = 'DELETE_SMS_TEMPLATE_BY_ID_SUCCESS';
export const LOADING = 'LOADING';

export const GetSMSTemplate = () => {

  return (dispatch) => {

    dispatch({ type: LOADING })

    axios({
      method: 'get',
      url: global.baseUrl + 'api/admin/getAllSMSFormat',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
    })
      .then(function (response) {
        if (response.data.status_code === "200" && response.data.status === "success") {
          var responsedata = response.data.data;
          getsmstemplatedetail(responsedata, dispatch);
          ActivityLog("GET_SMS_TEMPLATE")
        }
        else {
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const getsmstemplatedetail = (responsedata, dispatch) => {
  dispatch({
    type: GET_SMS_TEMPLATE_SUCCESS,
    payload: responsedata
  });
}

// Get SMS Template By ID --

export const GetSMSTemplateByID = (value) => {

  return (dispatch) => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/getSMSFormatByID',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title_id": value
      },
    })
      .then(function (response) {
        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {

          responsedata.data.ID = value;
          getsmstemplatedetailbyid(responsedata.data, dispatch);
          ActivityLog("GET_SMS_TEMPLATE_BY_ID")
        }
        else {
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const getsmstemplatedetailbyid = (responsedata, dispatch) => {
  dispatch({
    type: GET_SMS_TEMPLATE_BY_ID_SUCCESS,
    payload: responsedata
  });
}


// Update SMS Template By ID --

export const UpdateSMSTemplateByID = (value, history) => {

  return (dispatch) => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/updateSMSFormat',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title": value.title,
        "variables": value.variables,
        "format": value.format,
        "title_id": value.title_id
      },
    })
      .then(function (response) {
        var responsedata = response;

        if (response.data.status_code === "200" && response.data.status === "success") {

          updatesmstemplate(responsedata.data, dispatch);
          history.push('/templates/smstemplate');
          ActivityLog("UPDATE_SMS_TEMPLATE")

        }
        else {
          updatesmserror(responsedata, dispatch)
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const updatesmstemplate = (responsedata, dispatch) => {
  dispatch({
    type: UPDATE_SMS_TEMPLATE_SUCCESS,
    payload: responsedata
  });
}

export const updatesmserror = (responsedata, dispatch) => {
  dispatch({
    type: UPDATE_SMS_TEMPLATE_ERROR,
    payload: responsedata
  });
}

// Add SMS Template  --

export const AddSMSTemplateByID = (value, history) => {

  return (dispatch) => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/createSMSFormat',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title": value.title,
        "variables": value.variables,
        "format": value.format,
      },
    })
      .then(function (response) {
        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          addsmstemplate(responsedata, dispatch);
          history.push('/templates/smstemplate')
          ActivityLog("ADD_SMS_TEMPLATE")
        }
        else {
          addsmserror(responsedata, dispatch)
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const addsmstemplate = (responsedata, dispatch) => {
  dispatch({
    type: ADD_SMS_TEMPLATE_SUCCESS,
    payload: responsedata
  });
}

export const addsmserror = (responsedata, dispatch) => {
  dispatch({
    type: ADD_SMS_TEMPLATE_ERROR,
    payload: responsedata
  });
}

// Delete SMS Template By ID --

export const DeleteSMSTemplateByID = (value) => {

  return (dispatch) => {

    axios({
      method: 'delete',
      url: global.baseUrl + 'api/admin/removeSMSFormat',
      headers: {
        'Authorization':  global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title_id": value.ID
      },
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          smsdeletebyid(responsedata, dispatch);
          dispatch(GetSMSTemplate());
          ActivityLog("REMOVE_SMS_TEMPLATE")
        }
        else {
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const smsdeletebyid = (responsedata, dispatch) => {
  dispatch({
    type: DELETE_SMS_TEMPLATE_BY_ID_SUCCESS,
    payload: responsedata
  });
}