import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  error: false,
  errormessage: "",
  successmessage: "",
  message: "",
  getprofiledetails: []
};

const getprofileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE_SUCCESS: {
      return {
        ...state, getprofiledetails: action.payload, loading: false, error: false,
      };
    }

    case actionTypes.GET_PROFILE_ERROR: {
      return {
        ...state, loading: false, error: true, errormessage: action.payload
      };
    }

    case actionTypes.LOADING: {
      return {
        ...state, loading: true, error: false,
      };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }
    default: {
      return state;
    }
  }
};

export default getprofileReducer;
