import axios from 'axios';
import global from '../globalconfig.json';
import { GetVariableSettingsActions } from './GetVariableSettingsActions';
import { ActivityLog } from 'src/actions';


export const VARIABLE_UPDATE_SUCCESS = 'VARIABLE_UPDATE_SUCCESS';
export const VARIABLE_UPDATE_ERROR = 'VARIABLE_UPDATE_ERROR';
export const LOADING = 'LOADING';


export const variableupdate = (values) => {

  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/updateVariableSettings',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "value": values.value

      },
    })
      .then(function (response) {


        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {


          varupdatesuccess(responsedata, dispatch);
          dispatch(GetVariableSettingsActions())
          ActivityLog("VARIABLE_UPDATE")
        }
        else {

          variableerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {

      });
  };
}

export const varupdatesuccess = (responsedata, dispatch) => {
  dispatch({
    type: VARIABLE_UPDATE_SUCCESS,
    payload: responsedata
  });
}

export const variableerror = (responsedata, dispatch) => {
  dispatch({
    type: VARIABLE_UPDATE_ERROR,
    payload: responsedata
  });
}
