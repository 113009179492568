import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';


export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_ERROR = 'GET_PACKAGE_ERROR'
export const LOADING = 'LOADING';

export const GetPackageActions = (get_id) => {

  return async (dispatch) => {

    dispatch({ type: LOADING })

    await axios({
      method: 'post',
      url: global.baseUrl + 'api/v1/getCustomerPackageByClientID',
      headers: {
        'Authorization': global.customertoken,
        'Content-Type': 'application/json'
      },
      data: {
        "client_id": get_id
      },

    })
      .then(async function (response) {

        if (response.data.status_code === "200" && response.data.status === "success") {

          var responsedata = response.data
          if (responsedata.message === "DATA NOT FOUND.") {
            responsedata = ""
          }
          else {
            var responsedata = response.data.data[0].data;
            var data = []
            for (let i = 0; i < responsedata.module.length; i++) {
              if (responsedata.module[i].moduleid !== "") {
                getmodulename(responsedata.module[i].moduleid).then((result) => data.push(result))
              }
            }
          }
          responsedata.modulename = data

          setTimeout(() => {
            getpackage(responsedata, dispatch);
          }, 2000);
        }
        else {
          getpackageerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const getpackage = (responsedata, dispatch) => {
  dispatch({
    type: GET_PACKAGE_SUCCESS,
    payload: responsedata
  });
}

export const getpackageerror = (responsedata, dispatch) => {
  dispatch({
    type: GET_PACKAGE_ERROR,
    payload: responsedata
  });
}



//  GET MODULE NAME
function getmodulename(tblid) {

  return axios({
    method: 'post',
    url: global.baseUrl + 'api/admin/getMasterByID',
    headers: {
      'Authorization': global.apitoken,
      'Content-Type': 'application/json'
    },
    data: {
      "nameti": "module",
      "title_id": tblid
    }
  })
    .then(function (response) {

      var responsedata = response.data;

      if (response.data.status_code === "200" && response.data.status === "success") {
        return responsedata.data.value;
      }
      else {
      }

    }.bind(this))
    .catch((error) => { });
}