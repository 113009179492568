import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';


export const GET_EMAIL_TEMPLATE_SUCCESS = 'GET_EMAIL_TEMPLATE_SUCCESS';
export const GET_EMAIL_TEMPLATE_BY_ID_SUCCESS = 'GET_EMAIL_TEMPLATE_BY_ID_SUCCESS';
export const GET_EMAIL_TEMPLATE_BY_ID_ERROR = 'GET_EMAIL_TEMPLATE_BY_ID_ERROR';
export const UPDATE_EMAIL_TEMPLATE_BY_ID_SUCCESS = 'UPDATE_EMAIL_TEMPLATE_BY_ID_SUCCESS';
export const UPDATE_EMAIL_TEMPLATE_BY_ID_ERROR = 'UPDATE_EMAIL_TEMPLATE_BY_ID_ERROR';
export const DELETE_EMAIL_TEMPLATE_BY_ID_SUCCESS = 'DELETE_EMAIL_TEMPLATE_BY_ID_SUCCESS';
export const DELETE_EMAIL_TEMPLATE_BY_ID_ERROR = 'DELETE_EMAIL_TEMPLATE_BY_ID_ERROR';
export const ADD_EMAIL_TEMPLATE_SUCCESS = 'ADD_EMAIL_TEMPLATE_SUCCESS';
export const ADD_EMIAL_TEMPLATE_ERROR = 'ADD_EMIAL_TEMPLATE_ERROR';
export const LOADING = 'LOADING';

export const GetEmailTemplate = () => {

  return (dispatch) => {

    dispatch({ type: LOADING })

    axios({
      method: 'get',
      url: global.baseUrl + 'api/admin/getAllEmailFormat',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
    })
      .then(function (response) {
        if (response.data.status_code === "200" && response.data.status === "success") {
          var responsedata = response.data.data;

          getemailtemplatedetail(responsedata, dispatch);
          ActivityLog("GET_EMAIL_TEMPLATE")
        }
        else {
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const getemailtemplatedetail = (responsedata, dispatch) => {
  dispatch({
    type: GET_EMAIL_TEMPLATE_SUCCESS,
    payload: responsedata
  });
}

// Get Email Template By ID --

export const GetEmailTemplateByID = (value) => {

  return (dispatch) => {

     dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/getEmailFormatByID',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title_id": value
      },
    })
      .then(function (response) {
        var responsedata = response.data.data;
        responsedata.ID = value;
        if (response.data.status_code === "200" && response.data.status === "success") {
          //responsedata.data.ID = value;
          getemailtemplatedetailbyid(responsedata, dispatch);
          ActivityLog("GET_EMAIL_TEMPLATE_BY_ID")
        } else {
          getemailbyiderror(responsedata, dispatch);
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const getemailtemplatedetailbyid = (responsedata, dispatch) => {
  dispatch({
    type: GET_EMAIL_TEMPLATE_BY_ID_SUCCESS,
    payload: responsedata
  });
}

export const getemailbyiderror = (responsedata, dispatch) => {
  dispatch({
    type: GET_EMAIL_TEMPLATE_BY_ID_ERROR,
    payload: responsedata
  });
}
// Update SMS Template By ID --

export const UpdateEmailTemplateByID = (value, history) => {

  
  return (dispatch) => {
    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/updateEmailFormat',
      headers: {
        'Authorization':  global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title": value.title,
        "subject": value.subject,
        "variables": value.variables,
        "format": value.format,
        "title_id": value.title_id
      },
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          updateemailtemplate(responsedata, dispatch);
          history.push('/templates/emailtemplate');
          ActivityLog("UPDATE_EMAIL_TEMPLATE")

        }
        else {
          updateemailerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const updateemailtemplate = (responsedata, dispatch) => {
  dispatch({
    type: UPDATE_EMAIL_TEMPLATE_BY_ID_SUCCESS,
    payload: responsedata
  });
}

export const updateemailerror = (responsedata, dispatch) => {
  dispatch({
    type: UPDATE_EMAIL_TEMPLATE_BY_ID_ERROR,
    payload: responsedata
  });
}

// Delete Emial Template By ID --

export const DeleteEmialTemplateByID = (value) => {

  return (dispatch) => {
    axios({
      method: 'delete',
      url: global.baseUrl + 'api/admin/removeEmailFormat',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title_id": value.ID
      },
    })
      .then(function (response) {
        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          emaildeletebyid(responsedata, dispatch);
          dispatch(GetEmailTemplate());
          ActivityLog("REMOVE_EMAIL_TEMPLATE")
        }
        else {
          emaildeleteerror(responsedata, dispatch);
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const emaildeletebyid = (responsedata, dispatch) => {
  dispatch({
    type: DELETE_EMAIL_TEMPLATE_BY_ID_SUCCESS,
    payload: responsedata
  });
}

export const emaildeleteerror = (responsedata, dispatch) => {
  dispatch({
    type: DELETE_EMAIL_TEMPLATE_BY_ID_ERROR,
    payload: responsedata
  });
}


// Add SMS Template --

export const AddEmialTemplateByID = (value, history) => {

  return (dispatch) => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/createEmailFormat',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "title": value.title,
        "subject": value.subject,
        "variables": value.variables,
        "format": "%name%email%textbox%",
      },
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
          addsmstemplate(responsedata, dispatch);
          history.push('/templates/emailtemplate')
          ActivityLog("ADD_EMAIL_TEMPLATE")

        }
        else {
          addsmserror(responsedata, dispatch)
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const addsmstemplate = (responsedata, dispatch) => {
  dispatch({
    type: ADD_EMAIL_TEMPLATE_SUCCESS,
    payload: responsedata
  });
}

export const addsmserror = (responsedata, dispatch) => {
  dispatch({
    type: ADD_EMIAL_TEMPLATE_ERROR,
    payload: responsedata
  });
}