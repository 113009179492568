import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  getsmstemplatedetails: [],
  getsmstemplatedetailsbyid: [],
  successmessage: '',
  errormessage: '',
  error: false,
  message: ''
};

const getsmstemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SMS_TEMPLATE_SUCCESS: {
      return {
        ...state, getsmstemplatedetails: action.payload, loading: false
      };
    }

    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }

    case actionTypes.GET_SMS_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state, getsmstemplatedetailsbyid: action.payload, loading: false, error: false
      };
    }

    case actionTypes.UPDATE_SMS_TEMPLATE_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, error: false, message: "SMS Template Updated Succcessfully."
      };
    }

    case actionTypes.UPDATE_SMS_TEMPLATE_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error: true, message: "Error Occured."
      };
    }

    case actionTypes.ADD_SMS_TEMPLATE_SUCCESS: {
      return {
        ...state, successmessage: action.payload, loading: false, message: "SMS Template Added Successfully."
      };
    }

    case actionTypes.ADD_SMS_TEMPLATE_ERROR: {
      return {
        ...state, errormessage: action.payload, loading: false, error: true, message: 'Error Occured.'
      };
    }
    case actionTypes.DELETE_SMS_TEMPLATE_BY_ID_SUCCESS: {
      return {
        ...state, successmessage: action.payload, message: "SMS Template Delete Successfully."
      };
    }
    case actionTypes.NULL_DATA: {
      return { ...state, errormessage: "", message: "", successmessage: "", loading: false, error: false };
    }

    default: {
      return state;
    }
  }
};

export default getsmstemplateReducer;
