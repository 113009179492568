import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';
import { getmaster } from './masterActions.js';

export const MASTER_UPDATE_SUCCESS = 'MASTER_UPDATE_SUCCESS';
export const MASTER_UPDATE_ERROR = 'MASTER_UPDATE_ERROR';
export const LOADING = 'LOADING';

export const MASTER_DELETE_SUCCESS = 'MASTER_DELETE_SUCCESS';
export const MASTER_DELETE_ERROR = 'MASTER_DELETE_ERROR';

export const masterupdateActions = (values) => {

  return dispatch => {
 

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/updateMaster',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "nameti": values.Type,
        "title_id": values.ID,
        "title": values.Title
      },
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
        

          masterupdatesuccess(responsedata, dispatch);
          dispatch(getmaster(values.Type, values.Action));
          ActivityLog("UPDATE_MASTER")
        }
        else {
          masterupdateerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
     
      });
  };
}

export const masterupdatesuccess = (responsedata, dispatch) => {
  dispatch({
    type: MASTER_UPDATE_SUCCESS,
    payload: responsedata
  });
}

export const masterupdateerror = (responsedata, dispatch) => {
  dispatch({
    type: MASTER_UPDATE_ERROR,
    payload: responsedata
  });
}


export const masterdeleteActions = (values) => {

  return dispatch => {
   
    dispatch({ type: LOADING })

    axios({
      method: 'delete',
      url: global.baseUrl + 'api/admin/removeMaster',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "nameti": values.Type,
        "title_id": values.ID
      },
    })
      .then(function (response) {

        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {


          masterdeletesuccess(responsedata, dispatch);
          dispatch(getmaster(values.Type, values.Action));
          ActivityLog("REMOVE_MASTER")
        }
        else {
          masterupdateerror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
   
      });
  };
}


export const masterdeletesuccess = (responsedata, dispatch) => {
  dispatch({
    type: MASTER_DELETE_SUCCESS,
    payload: responsedata
  });
}

export const masterdeleteerror = (responsedata, dispatch) => {
  dispatch({
    type: MASTER_DELETE_ERROR,
    payload: responsedata
  });
}
