import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  errormassage: '',
  successmessage: '',
  allcustomers: [],
  customer: [],
  customerid: ''
};

const SetcustomerPasswordReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionTypes.CUSTOMER_CREATE_PASSWORD_SUCCESS: {
      return {
        ...state, loading: false, errormassage: '', successmessage: action.payload, error: false, message: 'Credential created and send to customer.'
      };
    }
    case actionTypes.CUSTOMER_CREATE_PASSWORD_ERROR: {

      return { ...state, errormassage: action.payload, error: true, successmessage: '', loading: false, message: 'Error Occured!!' };
    }
    case actionTypes.LOADING: {
      return {
        ...state, loading: true
      };
    }
    case actionTypes.ADD_CUSTOMER_SUCCESS: {
      return {
        ...state, loading: false, errormassage: '', error: false, successmessage: action.payload, message: 'New customer added successfully!'
      };
    }
    case actionTypes.ADD_CUSTOMER_ERROR: {
      return {
        ...state, errormassage: action.payload, loading: false, error: true, message: 'Error Occured!!', successmessage: ''
      };
    }
    case actionTypes.GET_ALL_CUSTOMER_SUCCESS: {
      return {
        ...state, errormassage: '', allcustomers: action.payload, loading: false
      };
    }
    case actionTypes.GET_ALL_CUSTOMER_ERROR: {
      return {
        ...state, errormassage: "DATA GET ERROR", loading: false, error: true, loading: false
      };
    }
    case actionTypes.GET_CUSTOMER_SUCCESS: {
      return {
        ...state, errormassage: '', customer: action.payload, loading: false
      };
    }
    case actionTypes.GET_CUSTOMER_ERROR: {
      return {
        ...state, errormassage: "DATA GET ERROR", error: true, loading: false
      };
    }
    case actionTypes.CUSTOMER_RESET_AND_SET_PASSWORD_SUCCESS: {
      return {
        ...state, errormassage: '', successmessage: action.payload, loading: false, error: false, message: 'Password reset successfully!'
      };
    }
    case actionTypes.CUSTOMER_RESET_AND_SET_PASSWORD_ERROR: {
      return {
        ...state, errormassage: action.payload, error: true, loading: false, message: 'Error Occured!!', successmessage: ''
      };
    }
    case actionTypes.CUSTOMER_STATUS_SUCCESS: {
      return {
        ...state, errormassage: '', successmessage: action.payload, loading: false, error: false, message: 'Change Status successfully!'
      };
    }
    case actionTypes.CUSTOMER_STATUS_ERROR: {
      return {
        ...state, errormassage: action.payload, error: true, loading: false, message: 'Error Occured!!', successmessage: ''
      };
    }
      
    case actionTypes.UPDATE_CUSTOMER_SUCCESS: {
      return {
        ...state, errormassage: '', successmessage: action.payload, loading: false, error: false, message: 'Profile Updated successfully!'
      };
    }
    case actionTypes.UPDATE_CUSTOMER_ERROR: {
      return {
        ...state, errormassage: action.payload, error: true, loading: false, message: 'Error Occured!!', successmessage: ''
      };
    }

    case actionTypes.NULL_DATA: {
      return { ...state, errormassage: "", message: "", successmessage: "", loading: false, error: false };
    }
    case actionTypes.GET_CUSTOMER_ID: {
      return { ...state, customerid: action.payload };
    }

    default: {
      return state;
    }
  }
};

export default SetcustomerPasswordReducer;
