import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';

export const RESET_LINK = "RESET_LINK";
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const LOADING = 'LOADING';


export const resetpasswordActions = (values, history) => {

  return dispatch => {

    dispatch({ type: LOADING })

    axios({
      method: 'post',
      url: global.baseUrl + 'api/admin/resetpassword',
      headers: {
        'Authorization':  global.apitoken,
        'Content-Type': 'application/json'
      },
      data: {
        "newpassword": values.newpassword,
        "cpassword": values.confirmpassword,
        "resetlink":parseInt(values.resetlinksucess),

      },
    })
      .then(function (response) {


        var responsedata = response.data;

        if (response.data.status_code === "200" && response.data.status === "success") {
             resetsuccess(responsedata, dispatch);
             history.push('/auth/login');
             ActivityLog("RESET_PASSWORD")
        }
        else {
          passworderror(responsedata, dispatch)
        }

      }.bind(this))
      .catch((error) => {
      });
  };
}

export const resetsuccess = (responsedata, dispatch) => {
  dispatch({
    type: RESET_PASSWORD_SUCCESS,
    payload: responsedata
  });
}
export const resetlink = (values) => {
  return dispatch => {
    dispatch({
      type: RESET_LINK,
      payload: values
    });
  }
}
export const passworderror = (responsedata, dispatch) => {
  dispatch({
    type: RESET_PASSWORD_ERROR,
    payload: responsedata
  });
}
