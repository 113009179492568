import * as actionTypes from 'src/actions';

const initialState = {
  loading: false,
  error: false,
  errormessage: "",
  getpackagedetails: []
};

const getpackageReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PACKAGE_SUCCESS: {
      return {
        ...state, getpackagedetails: action.payload, loading: false, error: false,
      };
    }

    case actionTypes.GET_PACKAGE_ERROR: {
      return {
        ...state, loading: false, error: true, errormessage: action.payload
      };
    }
    
    case actionTypes.LOADING: {
      return {
        ...state, loading: true, error:false, 
      };
    }

    default: {
      return state;
    }
  }
};

export default getpackageReducer;
