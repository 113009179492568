import axios from 'axios';
import global from '../globalconfig.json';
import { ActivityLog } from 'src/actions';


export const GET_EMAIL_SETTINGS = 'GET_EMAIL_SETTINGS';
export const LOADING = 'LOADING';

export const GetEmailSettingsActions = () => {

  

  return async (dispatch) => {

    dispatch({ type: LOADING })

    await axios({
      method: 'get',
      url: global.baseUrl + 'api/admin/getEmailSettings',
      headers: {
        'Authorization': global.apitoken,
        'Content-Type': 'application/json'
      },
    })
      .then(async function (response) {

        if (response.data.status_code === "200" && response.data.status === "success") {
          var responsedata = response.data.data;
          await getemaildetail(responsedata, dispatch);
          ActivityLog("GET_EMAIL")
        }
        else {
        }

      }.bind(this))
      .catch(function (error) {
      });
  };
}

export const getemaildetail = (responsedata, dispatch) => {
  dispatch({
    type: GET_EMAIL_SETTINGS,
    payload: responsedata
  });
}